import { mq } from '../../cssInJs'

export default{
  
  popupContainer : {
    backgroundColor:'rgba(0,0,0,0.8)',
    position: 'fixed',
    top:'0px',
    left:'0px',
    width:'100%',
    height: '100%',
    zIndex:'9999',
    
    '.item' : mq({
      position: 'absolute',
      width: '100%',
      maxWidth: ['320px', null, '410px'],
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#eee',
        //paddingTop: '2rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      margin: '0px',
      height: '90%',
        '.closeButton' : {
          textAlign: 'right',
          //margin: '1rem 0px',
          cursor: 'pointer',
          //color: 'white',
          marginTop: '0.5rem'
        },
      '&.land' : {
        maxWidth: '600px',
        // '.popup__item__container' : {
        //   //maxHeight : '120px !important' 
        // }
      }
    }),
  },

  popup__item__wrapper : mq({
    //maxHeight: ['600px', null, '850px'],
    overflow: "auto",
    height:'93%'
  }),
  
  popup__item_container : mq({
    fontSize: '0.8rem',
    //maxHeight: ['470px', null, '600px'],
    //overflow: "auto",
    marginTop: '1rem',
    paddingBottom: '1rem'
  }),
  __image : mq({
    maxWidth: ['265px', null, '330px'],
  })
}