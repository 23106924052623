//import { mq, variables } from '../../cssInJs'

import { variables, mq } from "../../cssInJs";

export default{
  page__container : {
    maxWidth: '960px',
    marginTop: '1rem',
    marginBottom: '1rem',
    //paddingBottom:'20rem',
    '.form-group label' : {
      display: 'none'
    }
  },
  banner__image : {
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  loading : {
    textAlign: 'center',
    marginTop: '7rem',
    'svg' : {
      width: '100px',
      height:'100px',
    }
  },
  playerInfo1 : {
    '.player__info1__title' : {
      fontWeight: '600'
    },
    '.player__info1__value' : {
      marginLeft: '1rem'
    }
  },

  gameInfo_games : {
    marginTop: '1rem',
    //maxWidth: '620px',

    '.main_title' : {
      color: variables.redColor,
      fontWeight: 600
    },

    '.item_container' : {
      
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      backgroundColor: '#f3f0f0',
      marginLeft: '0px',
      marginRight: '0px',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',

      '.item__link' : {
        fontWeight: '600'
      },
      
      '.game__item__title' : {
        fontWeight: '600'
      }
    }
  },

  competition__info : mq({
    width: ['100%', '80%']
  }),
  thumbnails : {
        display:'flex',
        flexWrap:'wrap',
        marginLeft:'-5px',
        marginRight:'-5px'
    },
    thumbnail : mq({
        //flexBasis: ['50%','25%','33.33%'],
        flexBasis: ['50%','25%','16.66%'],
        //padding: ['5px']
        padding:'5px',
        margin: 'auto',
        img : {
          maxWidth: '140px'
        } 
    })
}