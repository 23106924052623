import { variables, mq } from '../../../cssInJs';

export default{

  round__container : mq({
    margin: 'auto',
    marginTop: '0.5rem',
    maxWidth: ['300px', null, '1024px'] ,
    '.slick-prev' : {
      left: '-30px',
      '&:before':{
        color: 'black'
      } 
    },
    '.slick-next' : {
      right: '-30px',
      '&:before':{
        color: 'black'
      }
    }
  }),

  round__text : {
    textAlign: 'center',
    //marginLeft: '2px',
    //marginRight: '2px'
    paddingLeft: '1px',
    paddingRight: '1px',
    cursor: 'pointer',
    '.value' : {
      backgroundColor: '#ebebeb',
      '&.selected' : {
        backgroundColor: variables.redColor,
        color: 'white'
      }
    }
  },

  games__container : {
    marginTop: '1rem',
    marginBottom: '1rem',
    '&.afl' : {
      marginTop: '1rem'
    }
  },
  games : {
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  },
  game__date : {
    backgroundColor: variables.redColor,
    color: 'white',
    padding: '0.2rem 0.5rem',
    fontWeight: '600'
  },

  logo : mq({
    width: '80px',
    height: '80px',
    //margin: '0 10px 0 0',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: 'auto',
    float: ['unset', null, 'left']
  }),

  row : {
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    "&.even" : {
      backgroundColor: 'white'
    },
    "&.odd" : {
      backgroundColor: '#ebebeb'
    }
  },

  venue : {
    borderBottom: '1px solid black',
    paddingBottom: '0.5rem',
    marginBottom: '1rem'
  },

  teamName : mq({
    fontWeight: 600,
    marginLeft : ['0rem', null, '1rem'],
    position : ['static', null, 'absolute'],
    transform : ['unset', null, 'translateY(-50%)'],
    top: '50%',
    textAlign: 'center',
    display: ['block', null, 'inline'],
    lineHeight: 1,
    marginTop: ['0.3rem', null, '0rem'],
    'label' : {
      cursor: 'pointer'
    }
  }),

  homeAway : mq({
    display : ['block', null, 'inline'],
    marginBottom: ['0.5rem', null, '0px']
  }),

  marginContainer : {
    backgroundColor: '#585858',
    color: 'white',
    textAlign: 'right',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem'
  }
  
}