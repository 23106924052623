/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
//import utils from '../../../utils';
import { Button, usePost, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useState } from 'react';
import { GetLoginModel } from './loginFormModel';
import { useForm } from '../../../form';

export function TipppingLogin({setTippingSession}){

  const model = GetLoginModel({});
  const form = useForm(model, { usePlaceholder: true });
  const render = (name) => form.renderControl(name, null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const post = usePost();

  const onSubmit = ((e) => {
    form.validateForm(e, () => {
      const formData = { ...form.getValue() };
      post.send(env.apiBase + "/api/proxy/tippinglogin", formData);
      setSendingStatus('pending');
    });
  });

  if (post.done() && sendingStatus === 'pending') {
    const _data = post.response;
    //console.log("_data", _data);
    if(_data.results.tippingSessionInfo.Result.token){
      //console.log('token', _data.results.tippingSessionInfo.Result.token);
      setTimeout(() => {
        sessionStorage.setItem("tippingsession", _data.results.tippingSessionInfo.Result.token);
        setTippingSession(_data.results.tippingSessionInfo.Result.token);
      }, 1000);
    }
  }

  return(
    <div className="tipping__login__form">
      {/* <div> Tipping Login </div> */}
      <div className="row">
        <div className="col-md-4">
          {render('userName')}
        </div>
        <div className="col-md-4">
          {render('password')}
        </div>
        <div className = "col-md-4">
          <div className="actions" css={style.button__container}>
            <Button onClick={onSubmit} status={post.status}>Login</Button>
          </div>
        </div>
        <div className="col-12">
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
          {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}> Successfully logged in </span>}
        </div>
      </div>

    </div>
  )
}