/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import { GetSignUpModel } from './signUpFormModel';
import { useForm } from '../../../form';
import { Button, usePost, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useState } from 'react';

export function TipppingSignUp({setTippingSession, setWwtitchForm}){

  const model = GetSignUpModel({});
  const form = useForm(model, { usePlaceholder: true });
  const render = (name) => form.renderControl(name, null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const post = usePost();

  const onSubmit = ((e) => {
    form.validateForm(e, () => {
      const formData = { ...form.getValue() };
      post.send(env.apiBase + "/api/proxy/tippingsignup", formData);
      setSendingStatus('pending');
    });
  });

  if (post.done() && sendingStatus === 'pending') {
    const _data = post.response;
    //console.log("_data", _data);
    setTimeout(() => {
      sessionStorage.setItem("tippingsession", _data.results.tippingSessionInfo.Result.token);
      setTippingSession(_data.results.tippingSessionInfo.Result.token);
    }, 1000);
    setSendingStatus(null);
  }

  return(
    <div className="tipping__sign__up__form">
      <h4 style={{marginBottom: '0.5rem'}}> Sign Up </h4>
      <div style={{marginBottom:'0.5rem'}}>
        <span css={style.span__link} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setWwtitchForm(0);
              }}> Return to login page  </span>
      </div>
      <div className="row">
        <div className="col-12">
          {render('email')}
        </div>
        <div className="col-12 col-md-6">
          {render('password')}
        </div>
        <div className="col-12 col-md-6">
          {render('confirmPassword')}
        </div>
        <div className="col-12 col-md-6">
          {render('firstName')}
        </div>
        <div className="col-12 col-md-6">
          {render('lastName')}
        </div>
        <div className="col-12 col-md-6">
          {render('nickName')}
        </div>
        <div className="col-12 col-md-6">
          {render('phone')}
        </div>
        <div className="col-12" style={{textAlign:'center'}}>
          
          <div className="actions" css={style.button__container}>
            <Button onClick={onSubmit} status={post.status}> SUBMIT </Button>
          </div>
          
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
          {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}> Successfully sent!</span>}
        </div>
      </div>
    </div>
  )
}