//import { mq, variables } from '../../cssInJs'

import { variables, mq } from "../../cssInJs";

export default{
  page__container : {
    maxWidth: '960px',
    marginTop: '1rem',
    marginBottom: '1rem',
    //paddingBottom:'20rem'
  },
  banner__image : {
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  loading : {
    textAlign: 'center',
    marginTop: '7rem',
    'svg' : {
      width: '100px',
      height:'100px',
    }
  },
  gameInfo1 : {
    '.game__info1__title' : {
      fontWeight: '600'
    },
    '.game__info1__value' : {
      marginLeft: '1rem'
    }
  },
  gameInfo_score : {
    //maxWidth: '540px',
    maxWidth: '610px',
    marginTop: '1rem',
    '.title' : {
      color: variables.redColor,
      fontWeight: '600'
    },
    '.team_name' : {
      fontWeight: '600'
    },
    // '.q_style' : {
    //   fontWeight: '600'
    // }
    '.q_style' : mq({
      fontWeight: '600',
      display: ['block', 'inline']
    }),
    '.q4__col' : mq({
      //flexBasis: ['60px', null, "0"]
      flexBasis : '60px'
    })
  },
  gameInfo_players : {
    marginTop: '1rem',
    maxWidth: '620px',
    '.title' : {
      color: variables.redColor,
      fontWeight: '600',
    },
    '.item_container' : mq({
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      backgroundColor: '#f3f0f0',
      marginLeft: '0px',
      marginRight: '0px',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      '.item__link' : {
        fontWeight: '600'
      },
      '.player__item__title' : {
        fontWeight: '600'
      },
      '.row' : {
        
      }
    })
  }
}