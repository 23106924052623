import { mq, variables } from '../../cssInJs'

export default{
  player__profiles : {
    'button' : {
      //marginRight: '1rem'
      marginTop: '0.5rem',
      marginBottom: '0.5rem'
    }
  },

  players : mq({
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-10px',
    marginRight: '-10px',
    justifyContent: 'center'
  }),

  player : mq({
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: ['40%', null, '18%'],
    maxWidth: ['40%', null, '18%'],
    cursor: 'pointer',
    'img' : {
      width: '100%'
    },
    '.jumper__text' : {
      textAlign: 'center',
      color: variables.darkRecColor,
      fontSize: '0.8rem'
    },
    '.name__text' : {
      textAlign: 'center',
      color: variables.darkRecColor,
      fontWeight: 600,
      marginTop: '-5px',
      fontSize: '0.8rem'
    }

  }),

  popupContainer : {
    backgroundColor:'rgba(0,0,0,0.8)',
    position: 'fixed',
    top:'0px',
    left:'0px',
    width:'100%',
    height: '100%',
    zIndex:'9999',
    '.item' : {
      position: 'absolute',
      width: '100%',
      maxWidth: '900px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#eee',
        //paddingTop: '2rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      margin: '0px',
        '.closeButton' : {
          textAlign: 'right',
          //margin: '1rem 0px',
          cursor: 'pointer',
          //color: 'white',
          marginTop: '0.5rem'
        },
      '&.land' : {
        maxWidth: '600px',
        '.popup__item__container' : {
          maxHeight : '120px !important'      
        }
      }
    }
  },
  popup__item_container : mq({
    fontSize: '0.8rem',
    maxHeight: ['470px', null, '600px'],
    overflow: "auto",
    marginTop: '1rem'
  })
}