import { variables } from '../../cssInJs'

export default {
  ladderbuttonContainer : {
    padding: '2px'
  },
  ladderbutton : {
    backgroundColor: '#d9d9d9',
    //marginLeft: '0.5rem',
    //marginRight: '0.5rem',
    textAlign: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    cursor: 'pointer',
    color: "#585858",
    fontWeight: "600",
    '&.selected' : {
      backgroundColor: variables.redColor,
      color: 'white'
    }
  },
  laddersContainer : {
    marginTop: '5px',
    paddingLeft: '2px',
    paddingRight: '2px'
  },
  ladderContainer: {
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    "&.even" : {
      backgroundColor: '#ebebeb'
    },
    "&.odd" : {
      backgroundColor: 'white'
    },
  },
  ladderLogo : {
    width: '80px',
    height: '80px',
    float: 'left',
    //margin: '0 10px 0 0',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  teamName : {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '1rem',
    fontWeight: 600
  },
  teamResult : {
    margin: 'auto',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  },
  value : {
    //verticalAlign: 'middle',
    //marginTop: '1px',
    fontWeight: '600'
  }
  
  // ladderTitle : {
  //   marginTop: '0.5rem',
  //   marginBottom: '0.5rem',
  //   backgroundColor: variables.redColor,
  //   padding: '0.5rem',
  //   color: "white",
  //   fontWeight: "600"
  // }
}