/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import utils from '../../utils';
import style from './style';

export function MembershipCounter(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'membership__counter', item.cssClass || item.anchorName || '');
    const [memberCount, setMemberCount] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch("https://premier.sportsubs.com.au/public/membercount?comp=sanfl&team=nafc");
            const data = await res.json();
            setMemberCount(data);
        }
        fetchData();
    }, []);

    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                (memberCount && memberCount.isSuccess === true) &&
                <div css={style.container} className="row">
                    <div className='col-6'>
                        <label>
                            Current Member Count :
                        </label>
                        <div>
                            {memberCount.memberCount}
                        </div>
                    </div>
                    <div className='col-6'>
                        <label>
                            Goal Member Count :
                        </label>
                        <div>
                            3000
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}