import React from 'react';
import { BannerPanel } from './bannerPanel/view';
import { BannerItem } from './bannerItem/view';
import { Panel } from './panel/view';
import { Html } from './html/view';
// import { TestimonialPanel } from './testimonialPanel/view';
// import { TestimonialItem } from './testimonialItem/view';
import { ImagePanel } from './imagePanel/view';
// import { PageTiles } from './pageTiles/view';
//import { PageTilesStApollo } from '../../components/tiles/pageTileStApollo/view';
import { PageTilesSharkCageDiving } from '../../components/tiles/sharkcagediving/view';
import { SingleBanner } from './singleBanner/view';
import { ContactForm } from './contactForm/view';
import { VideoPanel } from './VideoPanel/view';
import { Code } from './code/view';
import { BlogPosts } from './blogPosts/view';
import { BlogPostTitle } from './blogPostTitle/view';
import { VideoHolder } from './videoHolder/view';
import { VideoItem } from './videoItem/view';
import { MultiColumnPanel } from './multiColumnPanel/view';
import { ExpandableHtml } from './expandableHtml/view';
import { PhotoGallery } from '../../components/photoGallery/view';
import { TestimonialPanel } from '../../components/testimonial/index';
import { ExpandablePanel } from '../../components/expandablePanel/view';
import { NewsFeed } from '../../components/newsFeed/view';
import { SliderAwardPanel } from '../../components/SliderAward/index';
import { HistorySearch } from '../../components/historySearch/view';
import { PageTilesBsyc } from '../../components/tiles/bsyc/view';
import cmsUtils from '../utils/cmsUtils';
import { HistoryGameView } from '../../components/historyGameView/view';
import { OnlineScoreboard } from '../../components/onlineScoreboard/view';
import { PlayerProfiles } from '../../components/playerProfiles/view';
import { TeamSelection } from '../../components/teamSelection/view';
import { Ladders } from '../../components/ladders/view';
import { Fixtures } from '../../components/fixtures/view';
import { Tipping } from '../../components/tipping/view';
import { UpComingGame } from '../../components/upComingGame/view';
import { PhotoGalleryNafc } from '../../components/photoGalleryNafc/view';
import { MembershipCounter } from '../../components/membershipCounter/view';
import { JumperList } from '../../components/jumperList/view';

//import { PrintShare } from './printShare/view';

export function Item(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems, cmsOption);

  let itemElt = (<></>);
  if (item.itemTypeCode === 'Panel') {
    itemElt = (<Panel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Html') {
    itemElt = (<Html item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'BannerPanel') {
    itemElt = (<BannerPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'BannerItem') {
    itemElt = (<BannerItem item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'TestimonialPanel') {
    const setting = {
      startRatingColor : '#2b707d'
    };
    itemElt = (<TestimonialPanel item={item} cmsOption={cmsOption} {...setting} />);
  } else if (item.itemTypeCode === 'ImagePanel') {
    itemElt = (<ImagePanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PageTiles') {
    
    let _tileType = cmsUtils.payload(item, 'TileType');
    const tileType = (_tileType && _tileType !== '') ? _tileType : '1';
    if(tileType === "2"){
      itemElt = (<PageTilesSharkCageDiving item={item} cmsOption={cmsOption} />);
    }
    else{
      itemElt = (<PageTilesBsyc item={item} cmsOption={cmsOption} />);
    }
  } else if (item.itemTypeCode === 'SingleBanner') {
    itemElt = (<SingleBanner item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ContactForm') {
    itemElt = (<ContactForm item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'VideoPanel') {
    itemElt = (<VideoPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Code') {
    itemElt = (<Code item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Blog-Posts') {
    itemElt = (<BlogPosts item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Blog-PostTitle') {
    itemElt = (<BlogPostTitle item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'MultiColumnPanel') {
    itemElt = (<MultiColumnPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ExpandableHtml') {
    itemElt = (<ExpandableHtml item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'VideoHolder') {
    itemElt = (<VideoHolder item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'VideoItem') {
    itemElt = (<VideoItem item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PhotoGallery') {
    itemElt = (<PhotoGallery item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ExpandablePanel') {
    itemElt = (<ExpandablePanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'NewsFeed') {
    itemElt = (<NewsFeed item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'SliderAwardPanel') {
    itemElt = (<SliderAwardPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'HistorySearch') {
    itemElt = (<HistorySearch item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'HistoryGameView') {
    itemElt = (<HistoryGameView item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'OnlineScoreboard') {
    itemElt = (<OnlineScoreboard item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PlayerProfiles') {
    itemElt = (<PlayerProfiles item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'TeamSelection') {
    itemElt = (<TeamSelection item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Ladders') {
    itemElt = (<Ladders item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Fixtures') {
    itemElt = (<Fixtures item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'UpComingGame') {
    itemElt = (<UpComingGame item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PhotoGalleryNafc') {
    itemElt = (<PhotoGalleryNafc item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Tipping') {
    itemElt = (<Tipping item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'MembershipCounter') {
    itemElt = (<MembershipCounter item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'JumperList') {
    itemElt = (<JumperList item={item} cmsOption={cmsOption} />);
  } else {
    itemElt = (<div>{item.itemTypeCode}</div>);
  }

  // const subItemsElts = subItems.map((subItem, index) =>
  //   <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  // );
  return (
    <>
      {itemElt}
      {/* {subItemsElts} */}
    </>
  );
}