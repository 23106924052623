//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
  pageTiles: css({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden'
  }),

  slider : css({
    '.slick-prev' : css(mq({
      left: ['0px !important', null, '-20px !important'],
    })),
    '.slick-next' : css(mq({
      right: ['0px !important', null, '-20px !important']
    })),
    '.slick-next:before, .slick-prev:before' : css({
      color:'rgb(31, 79, 156)'
    })
  }),

  pageTile__tile: css(mq({
    flexGrow: '1',
    flexShrink: '1',
    padding: '10px',

    //for type1
    flexBasis: ['100%', null, '50%'],
    maxWidth: ['100%', null, '50%'],

    //For type2
    '&.type2': css(mq({
      flexBasis: ['100%', null, '50%', '33.3%'],
      maxWidth: ['100%', null, '50%', '33.3%'],
      '&.type2.useSlider' : css({
        flexBasis:'100%',
        maxWidth:'100%',
        height: '100%'
      }),
      '.pageTile__sharkCage__details': css(mq({
        padding: ['0 25px', '0 25px', '0 11px', null, '0 30px'],
        marginTop: ['-11%', -56, -40, -36, -38],
        //marginTop: ['-11.5%', '-10.5%', '-12%', null, -56],
        '.top-image-bar': mq({
          height: [70, 60]
        }),
        '.info-title': css(mq({
          //fontSize: '1.15rem'
          //fontSize: '1.3rem'
          fontSize: '1.5rem'
        })),
        '.info-des': css(mq({
          //flex: '1 1 30px',
          //fontSize:'0.7rem',
          marginBottom: '0.3rem',
          overflow: 'hidden',
          //maxHeight: '49px'
        })),
        '.infoDesc': {
          //WebkitLineClamp: '2',
        },
        '.info-btn': css(mq({
          fontSize: '0.7rem',
          marginTop: '0.1rem'
        }))

      }))
    }))
  })),
  tile__content: css({
    '&:hover': {
      textDecoration: 'none',
      color: '#231f20',
      '.pageTile__sharkCage__bg': css({
        transform: 'scale(1.05)'
      }),
      '.info-wrapper' : {
        backgroundColor : variables.redColor,
        '.info-title, .info-des' : {
          color: 'white'
        },
        '.info-btn' : {
          backgroundColor: 'white',
          color: variables.redColor
        }
      }
    },
    color: '#231f20',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }),

  tile__bg__wrapper: css({
    //height:'80%',
    overflow: 'hidden',
    flex: '0 0 auto'
  }),

  tile__bg: css(mq({
    paddingTop: ['56%'],
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: '.5s'
  })),

  tile__details: css(mq({
    padding: ['0 18px', '0 35px', '0 25px', '0 35px', '0 60px'],
    //height: '250px',
    //marginTop: ['-58px', null, '-38px', '-52px', '-56px'],
    marginTop: ['-11.5%', -58, -37, -50, -56],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2'
  })),

  top__image__bar: css({
    backgroundImage: 'url(/assets/tile-base-large.png)',
    height: '80px',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }),
  info__wrapper: css({
    backgroundColor: variables.darkRecColor,
    padding: '0 1.5rem',
    paddingBottom: '1rem',
    //boxShadow: '5px 5px 5px #ebebeb',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    position:'relative',
    // '> div' : css({
    //      //marginTop: '0.5rem',
    //      //marginBottom: '0.5rem'
    // })
    '&::after' : {
      content: '""',
      width: '100%',
      height: '77%',
      position: 'absolute',
      top:'23%',
      left: '0px',
      backgroundColor:'transparent',
      boxShadow: '5px 5px 5px #ebebeb'
    }
  }),
  info__title: css({
    margin: '0.6rem 0',
    fontWeight: '900',
    //color: variables.redColor,
    color: 'white',
    fontSize: '1.5rem',
    lineHeight: 1.1
  }),
  info__des: css({
    marginBottom: '0.4rem',
    //overflow: 'hidden',
    //maxHeight: '160px',
    //flex: '1 1 auto',
    fontSize: '0.8rem',
    flexGrow: 1,
    color: 'white'
  }),
  infoDesc: {
    display: '-webkit-box',
    WebkitLineClamp: '6',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  info__btn: css({
    backgroundColor: variables.redColor,
    color: 'white',
    fontSize: '0.8rem',
    fontWeight: '700',
    padding: '0.2rem 0.5rem',
    display: 'inline-block',
    //maxWidth: '168px'
  })
}