import { mq, variables } from '../../cssInJs'

export default {

  historySearchForm : {
    '& .form__top' : {
      backgroundColor: variables.redColor,
      paddingTop: '1rem',
      paddingBottom: '1rem'
    },
    '& .form__bottom' : {
      paddingTop: '1rem',
      paddingBottom: '1rem'
    },
    '& .row' : {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '960px',
      //paddingLeft: '1rem',
      //paddingRight: '1rem'
      //marginTop: '1rem',
      //marginBottom: '1rem'
    },
    '& label.label' : {
      color: 'white',
      fontWeight: '700',
      fontSize: '0.9rem'
    },
    '& .form__bottom label.label' : {
      color: 'black'
    },
  },

  advancedSearchBtn : mq({
    marginTop: ['0px', null, '40px'],
    backgroundColor: 'white',
    borderColor: 'white',
    color: 'black',
    fontWeight: '600',
    '&:hover, &:focus' : {
      backgroundColor: 'white',
      borderColor: 'white',
      color: 'black'
    }
  }),
  submitBtnContainer : {
    textAlign: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  searchResultContainer : {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '960px',
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  item : {
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  },
  // game__item : {
  //   marginTop: '0.5rem',
  //   marginBottom: '0.5rem'
  // },
  gameItem__title2 : {
    marginLeft: '1rem'
  },
  year__played : mq({
    marginLeft: ['0px', null, '1rem'],
    display: ['block', null, 'inline']
  })
}