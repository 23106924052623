import { mq } from '../../cssInJs'

export default{
  item__container : {
    border: '1px solid',
    padding: '10px 5px'
  },
  itme__span1 : mq({

  }),
  itme__span2 : mq({
    display: ['block', null, 'inline'],
    marginTop: ['-5px', null, '0px']
  })
}