import { fb } from '../../lib/form';

export function getSearchFormModel(data) {
  
  const _years =  (data.Years && data.Years.length > 0) ? data.Years.map((item) => ({name : item, value : item})) : [];
  _years.unshift({name : 'Year', value : ''});

  
  // const _competitionTypes = (data.CompetitionTypes && data.CompetitionTypes.length > 0) ? data.CompetitionTypes.map((item) => ({name : item.CompetitionName, value : item.CompetitionCode})) : [];
  // _competitionTypes.unshift({name : 'Competition', value : ''});

  const _competitionTypes = (data.CompetitionTypes && data.CompetitionTypes.length > 0) ? data.CompetitionTypes.filter(x => x.CompetitionCode !== 'Women').map((item) => ({
    name : item.CompetitionName, value : item.CompetitionCode
  })) : [];
  _competitionTypes.unshift({name : 'Competition', value : ''});

  const _competitionTypesForWomen = (data.CompetitionTypes && data.CompetitionTypes.length > 0) ? data.CompetitionTypes.filter(x => x.CompetitionCode === 'Women').map((item) => ({
    name : item.CompetitionName, value : item.CompetitionCode
  })) : [];
  _competitionTypesForWomen.unshift({name : 'Competition', value : ''});

  const _rounds = (data.Rounds && data.Rounds.length > 0) ? data.Rounds.map((item) => ({name : item, value : item})) : [];
  _rounds.unshift({name : 'Round', value : ''});

  const _opponents = (data.Opponents && data.Opponents.length > 0) ? data.Opponents.map((item) => ({name : item.ClubName, value : item.ClubCode})) : [];
  _opponents.unshift({name : 'Opponent', value : ''});

  const _gameTypes = (data.GameTypes && data.GameTypes.length > 0) ? data.GameTypes.map((item) => ({name : item.GameTypeName, value : item.GameTypeCode})) : [];
  _gameTypes.unshift({name : 'Game Type', value : ''});

  const _menOrWomen = [
    {name : 'Select', value : ''},
    {name : 'Men', value : 'men'},
    {name : 'Women', value : 'women'}
  ];

  const model = fb.group({
    playerName: [data.PlayerName || '', [], { label: 'Player Name', type: 'text' }],
    year: [data.Year || '', [], { label: 'Year/Season', type: 'select', options : _years }],
    yearInAdvancedFilter: [data.YearInAdvancedFilter || '', [], { label: 'Year', type: 'select', options : _years }],
    
    competitionType: [data.CompetitionType || '', [], { label: 'Competition Type', type: 'select', options : _competitionTypes }],
    competitionTypeForWomen: [data.CompetitionType || '', [], { label: 'Competition Type', type: 'select', options : _competitionTypesForWomen }],
    
    round: [data.Round || '', [], { label: 'Round', type: 'select', options : _rounds }],
    _date: [data.Date || '', [], { label: 'Date', type: 'date' }],
    opponent: [data.Opponent || '', [], { label: 'Opponent', type: 'select', options : _opponents }],
    gameType: [data.GameType || '', [], { label: 'Game Type', type: 'select', options : _gameTypes }],
    useAdvancedFilter: [data.UseAdvancedFilter || false, [], { label: '', type: 'checkbox' }],
    menOrWomen : [data.menOrWomen || '', [], { label: 'Men / Women', type: 'select', options : _menOrWomen }],
    action: [data.action || 'search', [], { type: 'hidden' }]
  });
  return model;
}