import { fb, validators } from '../../../lib/form';

export function GetSignUpModel(data){
  const model = fb.group({
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    password: [data.password || '', [validators.Required(), validators.Length('Min 7 characters', {min: 7})], { label: 'Password', type: 'password' }],
    confirmPassword: [data.confirmPassword || '', [validators.Required()], { label: 'Confirm Password', type: 'password' }],
    firstName: [data.firstName || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    lastName: [data.lastName || '', [], { label: 'Last Name', type: 'text' }],
    nickName: [data.nickName || '', [validators.Required()], { label: 'Nick Name', type: 'text' }],
    phone: [data.phone || '', [], { label: 'Phone', type: 'text' }]
  }); 
  return model;
}