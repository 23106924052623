import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: '#ddd',
    fontSize: ['17px', null, '20px'],
    '&:hover' : {
      color: '#ddd'
    }
  })),
}