import { mq, variables } from '../../cssInJs'

export default{
  scoreboard__container : {
    maxWidth: '960px',
    margin: 'auto'
  },
  comment__list : {
    marginTop: '1rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    //backgroundColor: '#f3f0f0',
    maxWidth: '960px',
    margin: 'auto',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    'p' : mq({
      margin: '5px 0',
      marginRight: '20px',
      textIndent: '-20px',
      paddingLeft: '25px',
      fontSize: '0.8rem'
    }),
    '.title__text' : {
      color: variables.darkRecColor,
      fontWeight: 600,
      fontSize: '1.5rem'
    }
  },
  scoreboard : {
    marginTop: '1rem',
    marginBottom: '1rem',
    '.logo__container' : {
      textAlign: 'center'
    },
    '.logo' : mq({
      height: ['80px',null, '150px'],
    }),
    '.team__name' : mq({
      textAlign: 'center',
      fontWeight: 600,
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      fontSize: ['1.1rem', null, '1rem']
    }),
    '.vs' : mq({
      textAlign: 'center',
      margin: 'auto',
      fontSize: ['2rem', null, '3rem'],
      fontWeight: '600'
    }),
    '.col-5' : {
      paddingLeft: '0px',
      paddingRight: '0px'
    },
    '.score' : {
      textAlign: 'center',
      'span' : mq({
        backgroundColor: '#f3f0f0',
        marginLeft: '0.25rem',
        marginRight: '0.25rem',
        fontWeight: '600',
        width: ['35px', null, '110px'],
        fontSize: ['1.2rem', null, '3rem'],
        display: 'inline-block',
        '&.points' : {
          backgroundColor: variables.darkRecColor,
          color: 'white'
        }
      })
    }
  },
  scoreBoardInfo : {
    marginRight: '0px',
    marginLeft: '0px',
    backgroundColor: variables.darkRecColor,
    color: 'white',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  }
}