/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../utils';
import { Loading } from '../../components/';
import env from '../../env';
import { useEffect, useState } from 'react';

export function Ladders(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'ladders', item.cssClass || item.anchorName || '');
  const [trigger, setTrigger] = useState("nafc");
  const [currentLadder, setCurrentLadder] = useState("nafc");
  const [currentLadderResult, setCurrentLadderResult] = useState();
  const [ladderResult, setLadderResult] = useState(
    { nafc : null, reserve : null, u18 : null, u16 : null, women : null }
  );

  useEffect(() => { 
    if (cmsOption.isCmsEdit || trigger === ""){
      return;
    }
    
    const fetchData = async () => {

      const url = "/api/proxy/getladder/" + trigger;
      const res = await fetch(env.apiBase + url);
      const data = await res.json();

      const _ladders = { title : "", ladders : data.results.Result.result};
      switch(trigger){
        case "nafc" :
          _ladders.title = "League Ladder";
          setLadderResult({...ladderResult, nafc : _ladders });
          break;
        case "nafc_reserve" : 
          _ladders.title = "Reserve Ladder";
          setLadderResult({...ladderResult, reserve : _ladders });
          break;
        case "nafc_u18" : 
          _ladders.title = "U18 Ladder";
          setLadderResult({...ladderResult, u18 : _ladders });
          break;
        case "nafc_u16" : 
          _ladders.title = "U16 Ladder";
          setLadderResult({...ladderResult, u16 : _ladders });
          break;
        case "nafc_women" : 
          _ladders.title = "Women Ladder";
          setLadderResult({...ladderResult, women : _ladders });
          break;
        default :   
      }
      setCurrentLadderResult(_ladders);
    };
    
    fetchData();
    setTrigger("");
    // eslint-disable-next-line
  }, [trigger]);

  if (cmsOption.isCmsEdit){
    return(
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Ladders </b>
        </pre>
      </div>
    )
  }

  const showLadder = ((e, _ladderId) => {
    setCurrentLadder(_ladderId);
    setCurrentLadderResult(null);

    setTimeout(() => {
      if(_ladderId === "nafc"){
      if(ladderResult.nafc){
        setCurrentLadderResult(ladderResult.nafc);
        return;
      }
    }
    else if(_ladderId === "nafc_reserve"){
      if(ladderResult.reserve){
        console.log("fsdfdsf");
        setCurrentLadderResult(ladderResult.reserve);
        return;
      }
    }
    else if(_ladderId === "nafc_u18"){
      if(ladderResult.u18){
        setCurrentLadderResult(ladderResult.u18);
        return;
      }
    }
    else if(_ladderId === "nafc_u16"){
      if(ladderResult.u16){
        setCurrentLadderResult(ladderResult.u16);
        return;
      }
    }
    else if(_ladderId === "nafc_women"){
      if(ladderResult.women){ 
        setCurrentLadderResult(ladderResult.women);
        return;
      }
    }
    setTrigger(_ladderId);  
    }, 1500);

  });

  // if (get.loading()) {
  //   return <Loading />
  // } else if (get.hasErrors()) {
  //   return <ErrorPopup title="Loading Error" errors={get.errors} />
  // }

  // const data = get.response;
  // if(data && data.results && data.results.Result){
  //   console.log("Ladders", data);
  // }

  return(
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="row" style={{marginLeft: '0px', marginRight: '0px'}}>
        <div className="col-md col-6" css={style.ladderbuttonContainer}>
          <div className={currentLadder === "nafc" && "selected"} css={style.ladderbutton} onClick = {(e) => {
            if(currentLadder !== "nafc"){
              showLadder(e, "nafc");
            }
          }}> League Ladder </div>
        </div>
        <div className="col-md col-6" css={style.ladderbuttonContainer}> 
          <div className={currentLadder === "nafc_reserve" && "selected"} css={style.ladderbutton} onClick = {(e) => {
            if(currentLadder !== "nafc_reserve"){
              showLadder(e, "nafc_reserve");
            }
          }}> Reserve Ladder </div>
        </div>
        <div  className="col-md col-6" css={style.ladderbuttonContainer}>
          <div className={currentLadder === "nafc_u18" && "selected"} css={style.ladderbutton} onClick = {(e) => {
            if(currentLadder !== "nafc_u18"){
              showLadder(e, "nafc_u18");
            }
          }}> U18 Ladder </div>
        </div>
        <div  className="col-md col-6" css={style.ladderbuttonContainer}>
          <div className={currentLadder === "nafc_u16" && "selected"} css={style.ladderbutton} onClick = {(e) => {
            if(currentLadder !== "nafc_u16"){
              showLadder(e, "nafc_u16");
            }
          }}> U16 Ladder </div>
        </div>
        <div  className="col-md col-6" css={style.ladderbuttonContainer}>
          <div className={currentLadder === "nafc_women" && "selected"} css={style.ladderbutton} onClick = {(e) => {
            if(currentLadder !== "nafc_women"){
              showLadder(e, "nafc_women")
            }
          }}> Women&#39;s Ladder </div>
        </div>
      </div>
      
      {
        currentLadderResult ?
        <LaddersItem ladderInfo = {currentLadderResult}></LaddersItem> :
        <Loading></Loading>
      }

    </div>
  )
}

function LaddersItem(props){
  const ladderInfo = props.ladderInfo;
  //console.log('ladderInfo', ladderInfo);
  return(
    <div css={style.laddersContainer}>
      {
        ladderInfo.ladders.map((item, index) =>
          <div key = {index} className={`row ${index % 2 === 0 ? "even" : "odd"}`} css={style.ladderContainer}>
            <div className="col-md-4">
              <div css={style.ladderLogo} style={{backgroundImage: utils.css.bgUrlStyle(item.TeamImage)}}></div>
              <span css={style.teamName}>
                { item.TeamName }
              </span>
            </div>
            <div className="col-md-8" css={style.teamResult}>
              <div className="row" >
                <div className="col-4 col-md">
                  P : <span css={style.value}> {item.Played} </span>
                </div>
                <div className="col-4 col-md">
                  W : <span css={style.value}> {item.Won} </span>
                </div>
                <div className="col-4 col-md">
                  L : <span css={style.value}> {item.Lost} </span>
                </div>
                <div className="col-4 col-md">
                  D : <span css={style.value}> {item.Drawn} </span>
                </div>
                <div className="col-4 col-md">
                  PTS : <span css={style.value}> {item.Points} </span>
                </div>
                <div className="col-4 col-md">
                  <GetPercents percents = {item.Percents} />
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

function GetPercents({percents}){
  const _percents = (percents * 100).toFixed(2);
  return(
    <span css={style.value}> {(_percents.charAt(_percents.length -1) === '0' ? _percents.slice(0, -1) : _percents) + '%'}</span>
  )
}