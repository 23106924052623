import { fb } from '../../../lib/form';

export function GetReviewStandingModel(data){ 
  let list = data.compRounds.map(x => {
    return { name : x.CompRoundName, value : x.CompRoundId };
  });
  list = [{ name : "All", value : "0" }, ...list]
  const model = fb.group({
    compRound: [data.compRound || '0', [], { label: 'Round', type: 'select', options : list }]
  }); 
  return model;
}