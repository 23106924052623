//import { mq, variables } from '../../cssInJs'

export default{
  page__container : {
    maxWidth: '960px',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  banner__image : {
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  news__date : {
    marginBottom: '1rem',
    marginTop: '1rem'
  },
  loading : {
    textAlign: 'center',
    marginTop: '7rem',
    'svg' : {
      width: '100px',
      height:'100px',
    }
  }
}