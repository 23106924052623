//import { mq, variables } from '../../../cssInJs';

export default{
  button__container : {
    marginTop: '0rem',
    marginBottom: '0rem',
    'button' : {
      width: '100%'
    }
  }
}