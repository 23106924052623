/** @jsx jsx */
import { jsx } from '@emotion/core';
//import style from './style';
import { useEffect, useState, Fragment } from 'react';
import { Loading, ErrorMessage } from '../../../components';
import env from '../../../env';
import { GetReviewStandingModel } from './reviewStandingModel';
import { useForm } from '../../../form';

export function ReviewStanding(){

  const [sendingStatus, setSendingStatus] = useState("pending");
  const [errors, setErrors] = useState(); 
  const [standingInfo, setStandingInfo] = useState(null);
  const [compRounds, setCompRounds] = useState(null);
  const [roundId, setRoundId] = useState(0);

  useEffect(() => {
    if(!sendingStatus || sendingStatus !== "pending"){
      return;
    }

    const fetchData = async () => {
      const value = { roundId : roundId };
      const res = await fetch(env.apiBase + "/api/proxy/tippingstanding", {
        method : 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(value),
      });
      const _data = await res.json();

      if(_data.errors && _data.errors.length > 0){
        let checkSessionExpired = false;
        const _errors = [];
        for(let i in _data.errors){
          if(_data.errors[i].errorMessage === "session expired"){
            checkSessionExpired = true;
          }
          _errors.push(_data.errors[i].errorMessage);
        }
        setErrors(_errors);
        setSendingStatus(null);
        if(checkSessionExpired === true){
          //logOUt();
        }
        return;
      }

      if(_data.results.tippingStandingResult && _data.results.tippingStandingResult.Result.standing){
        const data = _data.results.tippingStandingResult.Result.standing;
        setStandingInfo(data);
        if(!compRounds){
          setCompRounds(data.CompRounds);
        }
        setSendingStatus(null);
      }
    } 
    fetchData();
    
    // eslint-disable-next-line
  }, [sendingStatus]);

  function changeStading(roundId){
    console.log("roundId", roundId);
    setRoundId(roundId);
    setSendingStatus("pending");
  }

  //console.log("standingInfo", standingInfo);

  return(
    <div style = {{marginTop: '1rem'}}>
      {
        (compRounds && compRounds.length > 0) &&
        <CompList compRounds = {compRounds} changeStading = {changeStading}></CompList>
      }
      {
        sendingStatus === 'pending' ?
        <Loading></Loading> :
        <Fragment>
          {
            (standingInfo && standingInfo.Ranks && standingInfo.Ranks.length > 0) ?
            <div>
              {
                standingInfo.Ranks.map((item, index) =>
                <RankView key={index} item = {item} />)
              }
            </div> :
            <div> Not available yet. </div>
          }
        </Fragment> 
      }

      <div style={{textAlign : 'center', marginTop:'0.5rem'}}>
        <ErrorMessage errors={errors} />
      </div>

    </div>
  )
}

function CompList({compRounds, changeStading}){
  
  const model = GetReviewStandingModel({compRounds});
  const form = useForm(model, { usePlaceholder: true, onControlChanged : orderItemChanged });
  const render = (name) => form.renderControl(name, null);
  
  function orderItemChanged(e, control){
    if (control && control.name === 'compRound'){
      changeStading(control.value);
    }
  }

  return(
    <div className="tipping__review__standing__form row">
      <div className="col-12 col-md-6">
        {render('compRound')}
      </div>
    </div>
  )
}

function RankView({item}){
  return(
    <div>
      <div>{item.RankTitle}</div>
      <div>{item.Points + " / " + item.TotalGames}</div>
      {
        item.UserScores && item.UserScores.length > 0 &&
        <div>
          {
            item.UserScores.map((userItem, index) => 
              <span key={index}> {userItem.NickName} </span>
            )
          }
        </div>
      }
    </div>
  )
}