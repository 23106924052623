import { variables, mq } from '../../cssInJs'

export default {
  fixtureButtonContainer : {
    padding: '2px'
  },
  fixtureButton : {
    backgroundColor: '#d9d9d9',
    //marginLeft: '0.5rem',
    //marginRight: '0.5rem',
    textAlign: 'center',
    paddingTop: '2.2rem',
    paddingBottom: '2.2rem',
    cursor: 'pointer',
    color: "#585858",
    fontWeight: "600",
    position:'relative',
    '&.selected' : {
      backgroundColor: variables.redColor,
      color: 'white'
    }
  },
  spanText : {
    lineHeight : '1.3',
    display:'inline-block',
    position: 'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%, -50%)',
    width:'100%',
    paddingLeft:'0.5rem',
    paddingRight:'0.5rem'
  },
  fixturesContainer : {
    marginTop: '5px',
    paddingLeft: '2px',
    paddingRight: '2px'
  },
  fixtureContainer: {
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    "&.even" : {
      backgroundColor: '#ebebeb'
    },
    "&.odd" : {
      backgroundColor: 'white'
    },
  },
  fixtureWrapper : {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem'
  },

  fixtureRoundName:{
    fontWeight: 700
  },
  
  fixtureBar:{
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  },

  fixtureDate:{
    fontWeight: 600
  },

  fixtureVanue : mq({
    marginLeft: ['0rem', null, '1rem'],
    fontSize: '0.8rem'
  }),

  hr : {
    marginTop: '0.3rem',
    marginBottom: '0.3rem'
  },

  fixtureLogo : mq({
    width: '80px',
    height: '80px',
    //margin: '0 10px 0 0',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: 'auto',
    float: ['unset', null, 'left']
  }),
  teamName : mq({
    fontWeight: 600,
    marginLeft : ['0rem', null, '1rem'],
    position : ['static', null, 'absolute'],
    transform : ['unset', null, 'translateY(-50%)'],
    top: '50%',
    textAlign: 'center',
    display: ['block', null, 'inline'],
    lineHeight: 1,
    marginTop: ['0.3rem', null, '0rem']  
  }),
  score : mq({
    fontWeight: 600,
    position : ['static', null, 'absolute'],
    transform : ['unset', null, 'translateY(-50%)'],
    top: '50%',
    left: "50%",
    textAlign: 'center',
    display: ['block', null, 'inline'],
    lineHeight: 1,
    marginTop: ['0.3rem', null, '0rem']  
  })
}