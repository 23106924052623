/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, Fragment } from 'react';
import style from './style';
import utils from '../../utils';
import { useGet } from '../../components';
import { FaSpinner } from "react-icons/fa";
import env from '../../env';
//import { SiteLink } from '../../lib/router';
//import { ViewPartial } from '../../cms/pages/partials/viewPartial';

export function HistoryGameView(props){

  //const { query } = useRouter();
  //const gameId = query.gameid || '';
  const gameId = props.gameId;
  //const gameId = "abc";
  const get = useGet();

  //console.log('gameId', gameId);

  useEffect(() => {
    get.send(env.apiBase + `/api/proxy/getgame/${gameId}`);

    // eslint-disable-next-line
  }, []);
  const dataResulst = get.response;
  return(
    dataResulst && dataResulst.results ? <GameView data = {dataResulst} gameId = {gameId}></GameView> : 
    <div css={style.loading}>
      <FaSpinner className="spinner_loading"></FaSpinner>
    </div>
  )
}

function GameView(props){
  const gameData = props.data.results.Result === undefined ? null : props.data.results.Result;
  const gameId = props.gameId;
  //const points = {myTeam :0, opponentTeam : 0};
  function getTeamPoint(type){
    let point = 0;
    if(type === "myteam"){
      if(gameData.MyTeamStats.Goals4Q){
        point = gameData.MyTeamStats.Goals4Q;
      }
      else if(gameData.MyTeamStats.Goals3Q){
        point = gameData.MyTeamStats.Goals3Q;
      }
      else if(gameData.MyTeamStats.Goals2Q){
        point = gameData.MyTeamStats.Goals2Q;
      }
      else if(gameData.MyTeamStats.Goals1Q){
        point = gameData.MyTeamStats.Goals1Q;
      }

      point = point * 6;

      if(gameData.MyTeamStats.Points4Q){
        point = point + gameData.MyTeamStats.Points4Q;
      }
      else if(gameData.MyTeamStats.Points3Q){
        point = point + gameData.MyTeamStats.Points3Q;
      }
      else if(gameData.MyTeamStats.Points2Q){
        point = point + gameData.MyTeamStats.Points2Q;
      }
      else if(gameData.MyTeamStats.Points1Q){
        point = point + gameData.MyTeamStats.Points1Q;
      }
    }
    else{
      if(gameData.OpponentStats.Goals4Q){
        point = gameData.OpponentStats.Goals4Q;
      }
      else if(gameData.OpponentStats.Goals3Q){
        point = gameData.OpponentStats.Goals3Q;
      }
      else if(gameData.OpponentStats.Goals2Q){
        point = gameData.OpponentStats.Goals2Q;
      }
      else if(gameData.OpponentStats.Goals1Q){
        point = gameData.OpponentStats.Goals1Q;
      }

      point = point * 6;

      if(gameData.OpponentStats.Points4Q){
        point = point + gameData.OpponentStats.Points4Q;
      }
      else if(gameData.OpponentStats.Points3Q){
        point = point + gameData.OpponentStats.Points3Q;
      }
      else if(gameData.OpponentStats.Points2Q){
        point = point + gameData.OpponentStats.Points2Q;
      }
      else if(gameData.OpponentStats.Points1Q){
        point = point + gameData.OpponentStats.Points1Q;
      }
    }
    return " (" + point + ")";
  }

  //points.myTeam = getTeamPoint();

  const getPlayerList = (() => { 
    let totalGoals =0, totalPoints = 0, totalBestPlayer = 0, totalMedalVotes = 0;

    const _plyerList =  gameData.PlayerStatsList.map((item, index) => {

      totalGoals = totalGoals + (item.Goals ? item.Goals : 0);
      totalPoints = totalPoints + (item.Points ? item.Points : 0);
      totalBestPlayer = totalBestPlayer + (item.BestPlayer ? item.BestPlayer : 0);
      totalMedalVotes = totalMedalVotes + (item.MedalVotes ? item.MedalVotes : 0);

      return(
        <div key={index} className="row item_container">
          <div className="col-12">
                      {/* <SiteLink to = {`/history-player/${item.PlayerId}`} className="item__link">
                        { item.Guernsey + '. ' + item.Surname + ', ' +  item.OtherNames }
                      </SiteLink>  */}
                      <a href = {`/history/search?type=player&id=${item.PlayerId}`} className="item__link" onClick = {() => {
                        utils.site.setupLsForSearchUrl(window.location.pathname  + "?type=game&id=" + gameId);
                      }}>
                        { item.Guernsey + '. ' + item.Surname + ', ' +  item.OtherNames }
                      </a> 
                    </div>
                    <div className="col-6">
                      <span className="player__item__title"> Goals : </span>
                      <span> {item.Goals} </span>
                    </div>

                    <div className="col-6">
                      <span className="player__item__title"> Points : </span>
                      <span> {item.Points} </span>
                    </div>

                    <div className="col-6">
                      <span className="player__item__title"> Best Player : </span>
                      <span> {item.BestPlayer} </span>
                    </div>

                    <div className="col-6">
                      <span className="player__item__title"> Medal Votes : </span>
                      <span> {item.MedalVotes} </span>
                    </div>
        </div>
      )
    });
    return(
      <Fragment>
        { _plyerList  }
        <div className="row item_container">
                    <div className="col-12 item__link">
                      { "Total (" + gameData.PlayerStatsList.length + " players)"}
                    </div>
                    <div className="col-6">
                      <span className="player__item__title"> Goals : </span>
                      <span> {totalGoals} </span>
                    </div>

                    <div className="col-6">
                      <span className="player__item__title"> Points : </span>
                      <span> {totalPoints} </span>
                    </div>

                    <div className="col-6">
                      <span className="player__item__title"> Best Player : </span>
                      <span> {totalBestPlayer} </span>
                    </div>

                    <div className="col-6">
                      <span className="player__item__title"> Medal Votes : </span>
                      <span> {totalMedalVotes} </span>
                    </div>

                  </div>
      </Fragment>
    )

  });


  return(
    <Fragment>
      <div className="game__view">
        {/* <div css = {style.banner__image} className = "cms_item' 'single-banner' 'banner-item bannerSize--standard" 
          style={{ backgroundImage: utils.css.bgUrlStyle('http://nafc.com.au/-/nafc/lib/images/images_2560/pastgames.jpg') }}>
        </div> */}
        <div className="container" css = {style.page__container}>
          {
            gameData ?
            <Fragment>
              <h2>{ gameData.DistinctName }</h2>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Season: </span>
                <span className="game__info1__value"> {gameData.Year} </span>
              </div>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Competition: </span>
                <span className="game__info1__value"> {gameData.CompetitionName} </span>
              </div>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Game Type: </span>
                <span className="game__info1__value"> {gameData.GameTypeName} </span>
              </div>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Round: </span>
                <span className="game__info1__value"> {gameData.Round} </span>
              </div>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Opponent: </span>
                <span className="game__info1__value"> {gameData.OpponentTeam} </span>
              </div>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Oval: </span>
                <span className="game__info1__value"> {gameData.OvalName} </span>
              </div>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Date: </span>
                {/* <span className="game__info1__value"> {gameData.GameDate} </span> */}
                <span className="game__info1__value"> {utils.date.formatDDMMYYYY(gameData.GameDate)} </span>
              </div>
              <div css = {style.gameInfo1}>
                <span className="game__info1__title"> Result: </span>
                <span className="game__info1__value"> {gameData.Result} </span>
              </div>

              <div css={style.gameInfo_score}>
                <div className='title'> Scores </div>
                <div className="row">
                  <div className = "col-12 team_name">
                    North Adelaide
                  </div>
                  <div className="col">
                  <span className="q_style"> Q1 : </span> 
                  <span> {gameData.MyTeamStats.Goals1Q + '.' + gameData.MyTeamStats.Points1Q} </span> 
                </div>
                <div className="col">
                  <span className="q_style"> Q2 : </span> 
                  <span> {gameData.MyTeamStats.Goals2Q + '.' + gameData.MyTeamStats.Points2Q} </span> 
                </div>
                <div className="col">
                  <span className="q_style"> Q3 : </span> 
                  <span> {gameData.MyTeamStats.Goals3Q + '.' + gameData.MyTeamStats.Points3Q} </span> 
                </div>
                <div className="col q4__col">
                  <span className="q_style"> Q4 : </span> 
                  <span> {gameData.MyTeamStats.Goals4Q + '.' + gameData.MyTeamStats.Points4Q + getTeamPoint("myteam")} </span> 
                </div>
                  
                </div>

                <div className="row" style={{marginTop: '0.5rem'}}>
                  <div className = "col-12 team_name">
                    {gameData.OpponentTeam}
                  </div>
                  <div className="col">
                  <span className="q_style"> Q1 : </span> 
                  <span> {gameData.OpponentStats.Goals1Q + '.' + gameData.OpponentStats.Points1Q} </span> 
                </div>
                <div className="col">
                  <span className="q_style"> Q2 : </span> 
                  <span> {gameData.OpponentStats.Goals2Q + '.' + gameData.OpponentStats.Points2Q} </span> 
                </div>
                <div className="col">
                  <span className="q_style"> Q3 : </span> 
                  <span> {gameData.OpponentStats.Goals3Q + '.' + gameData.OpponentStats.Points3Q} </span> 
                </div>
                <div className="col q4__col">
                  <span className="q_style"> Q4 : </span> 
                  <span> {gameData.OpponentStats.Goals4Q + '.' + gameData.OpponentStats.Points4Q + getTeamPoint("opponentteam")} </span> 
                </div> 
                </div>
              </div>

              <div css={style.gameInfo_players}>
                <div className='title'> Players </div>
                {
                  getPlayerList()
                }
              </div>

            </Fragment> : "No Game Page"
          }

        </div>
      </div>
      {/* <ViewPartial /> */}
    </Fragment>
  )
}