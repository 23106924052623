import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: variables.darkRecColor,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    //height: [70, null, 110],
    height: [112, null, 176],
    marginTop: ['5.5rem', null, '7rem'],
    transition:'all .2s' 
  })),
  logoScrolled: css(mq({
    img : css(mq({
      //height: [45, null, 55],
      height: [70, null, 110],
      //marginTop:'0rem',
      marginTop: ['3rem', null, '3.5rem'],
    }))
  })),

  logo2Img : mq({
    position:'absolute',
    //top : ['-2px'],
    height:['40px', null, '52px'],
    left: ['3rem', null, '3.5rem']
  })

}