import { fb } from '../../lib/form';

export function getSearchGameYearModel(data){
  
  data.unshift({name : 'Year - All', value : ''});
  
  const model = fb.group({
    years: [data.year || '', [], { label: '', type: 'select', options : data }]
  });
  return model;
}