//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import { useEffect, useState } from 'react';

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const [memberCount, setMemberCount] = useState();
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index} memberCount = {memberCount}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__bsyc', item.cssClass || item.anchorName || '');

  useEffect(() => {
    const fetchData = async () => {
        const res = await fetch("https://premier.sportsubs.com.au/public/membercount?comp=sanfl&team=nafc");
        const data = await res.json();
        setMemberCount(data);
    }
    fetchData();
  }, []);

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
     css = {style.pageTiles}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {

  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme = page.colorTheme;
  const index = props.idx;
  const memberCounterGoal = page.memberCounterGoal;
  const memberCount = props.memberCount
  
  return (
    
    <div css = {style.pageTile__tile} className="pageTile__bsyc">
      <SiteLink className="pageTile__bsyc__content" css = {style.tile__content} to={disableLink ? '' : page.pageUrl}>
      <div css={style.tile__flip__wrapper}>
        
        <div className="pageTile__bsyc__bg" data-aos={index % 2 === 0 ? 'flip-up' : 'flip-down'} css = {style.tile__bg}>
          <div className="pageTile__bsyc__bg2" css = {style.tile__bg2} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        
        <div css = {style.tile__details} className={`pageTile__bsyc__content__details ${colorTheme}`} data-aos={index % 2 === 0 ? 'flip-down' : 'flip-up'}>  
          <div className="pageTile__bsyc__container">
            
            {
              (page.pageTitle && page.pageTitle !== '') &&
              <div className="pageTile__bsyc__title">
                {page.pageTitle}
              </div>
            }

            <div className="pageTile__bsyc__desc">
              {page.tileDesc || page.description}
            </div>

            {
              ((memberCounterGoal && memberCounterGoal !== '') && (memberCount && memberCount.isSuccess === true)) &&
              <div css={style.memberCounter}>
                <div css = {style.memberCounterItem}>
                   <label>
                      Current Member Count
                    </label>
                    <div className='member__count'>
                      {memberCount.memberCount}
                    </div>
                </div>
                {/* <div css = {style.memberCounterItem}>
                  <label>
                    Goal Member Count :
                  </label>
                  <div>
                    {memberCounterGoal}
                  </div>
                </div> */}
              </div>
            }

            {/* <div className="pageTile__bsyc__btn" css={style.pageTile__bsyc__btn}>
              FIND OUT MORE &gt;
            </div> */}

          </div>
        </div>
        </div>
      </SiteLink>
    </div>
  )
}