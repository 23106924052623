import { variables } from '../../cssInJs'

export default{
    container : {
        maxWidth: '960px',
        margin: 'auto',
        border: '1px solid',
        padding: '1rem',
        textAlign: 'center',
        marginTop: '1rem',
        marginBottom: '1rem',
        backgroundColor: variables.primaryColor,
        color: 'white',
        fontWeight: 600,
        'label' : {
            marginBottom:0
        }
    }
}