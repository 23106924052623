/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { useEffect } from 'react';
import style from './style';
import utils from '../../utils';
import { useRouter } from '../../components';
//import { FaSpinner } from "react-icons/fa";
//import env from '../../env';
import { HistoryGameView as GameView } from '../../site/game/gameView';
import { HistoryPlayerView as PlayerView } from '../../site/player/playerView';

export function HistoryGameView(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'hstoryGameView', 'container', item.cssClass || item.anchorName || '');

  const { query } = useRouter();
  //const gameId = query.gameId || '';

  const type = query.type || '';
  const id = query.id || '';

  const lsInfo = utils.site.reSetupLsForSearch();

  if (cmsOption.isCmsEdit){
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> History Search View </b>
        </pre>
        </div>
    )
  }

  return( 
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {
        lsInfo &&
        <div className = "container" css={style.backToResultBtnContainer}>
          <a href = {lsInfo} onClick={() => {
            utils.site.backBtnCheckInResult();
          }}> Back To Result</a>
        </div>
      }

      {
        type === "game" ?
          <GameView gameId = {id}></GameView> :
          <PlayerView playerId = {id}></PlayerView>
      }

      {
        lsInfo &&
        <div className = "container" css={style.backToResultBtnContainer}>
          <a href = "/history" onClick={() => {
            utils.site.backBtnCheckInResult();
          }}> Back To Result</a>
        </div>
      }

    </div>
  )

}