/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../utils';
import { Loading } from '../../components/';
import env from '../../env';
import { useEffect, useState } from 'react';

export function Fixtures(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'fixtures', item.cssClass || item.anchorName || '');
  const [currentFixture, setCurrentFixture] = useState("nafc");
  const [currentFixtureData, setCurrentFixtureData] = useState();
  const [trigger, setTrigger] = useState("nafc");
  const [fixtures, setFixtures] = useState(
    { nafc : null, reserve : null, u18 : null, u16 : null, women : null }
  );


  useEffect(() => {
    if (cmsOption.isCmsEdit || trigger === ""){
      return;
    }

    const fetchData = async () => {
      const url = "/api/proxy/getfixture/" + trigger;
      const res = await fetch(env.apiBase + url);
      const data = await res.json();

      //console.log("dddd", data.results.Result);

      const _fixtures = { title : "", fixture : data.results.Result};
      
      switch(trigger){
        case "nafc" :
          _fixtures.title = "League Fixture";
          setFixtures({...fixtures, nafc : _fixtures });
          break;
        case "nafc_reserve" : 
          _fixtures.title = "Reserve Fixture";
          setFixtures({...fixtures, reserve : _fixtures });
          break;
        case "nafc_u18" : 
          _fixtures.title = "U18 Fixture";
          setFixtures({...fixtures, u18 : _fixtures });
          break;
        case "nafc_u16" : 
          _fixtures.title = "U16 Fixture";
          setFixtures({...fixtures, u16 : _fixtures });
          break;
        case "nafc_women" : 
          _fixtures.title = "Women Fixture";
          setFixtures({...fixtures, women : _fixtures });
          break;
        case "nafc_womendevelop" : 
          _fixtures.title = "Women's Development Fixture";
          setFixtures({...fixtures, womendevelop : _fixtures });
          break;
        default :   
      }
      setCurrentFixtureData(_fixtures);
    };

    fetchData();
    setTrigger("");
    
    // eslint-disable-next-line
  }, [trigger]);


  const showLadder = ((e, fixtureId) => {
    setCurrentFixture(fixtureId);
    setCurrentFixtureData(null);
    
    setTimeout(() => {
      if(fixtureId === "nafc"){
        if(fixtures.nafc){
          setCurrentFixtureData(fixtures.nafc);
          return;
        }
      }
      else if(fixtureId === "nafc_reserve"){
        if(fixtures.reserve){
          setCurrentFixtureData(fixtures.reserve);
          return;
        }
      }
      else if(fixtureId === "nafc_u18"){
        if(fixtures.u18){
          setCurrentFixtureData(fixtures.u18);
          return;
        }
      }
      else if(fixtureId === "nafc_u16"){
        if(fixtures.u16){
          setCurrentFixtureData(fixtures.u16);
          return;
        }
      }
      else if(fixtureId === "nafc_women"){
        if(fixtures.women){ 
          setCurrentFixtureData(fixtures.women);
          return;
        }
      }
      else if(fixtureId === "nafc_womendevelop"){
        if(fixtures.womendevelop){ 
          setCurrentFixtureData(fixtures.womendevelop);
          return;
        }
      }
      setTrigger(fixtureId);
    }, 1500);
  });

  if (cmsOption.isCmsEdit){
    return(
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Fixture </b>
        </pre>
      </div>
    )
  }

  return(
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="row" style={{marginLeft: '0px', marginRight: '0px'}}>
        
        <div className="col-md col-6" css={style.fixtureButtonContainer}>
          <div className={currentFixture === "nafc" && "selected"} css={style.fixtureButton} onClick = {(e) => {
            if(currentFixture !== "nafc"){
              showLadder(e, "nafc");
            }
          }}> <span css={style.spanText}> League Fixture </span> </div>
        </div>

        <div className="col-md col-6" css={style.fixtureButtonContainer}> 
          <div className={currentFixture === "nafc_reserve" && "selected"} css={style.fixtureButton} onClick = {(e) => {
            if(currentFixture !== "nafc_reserve"){
              showLadder(e, "nafc_reserve");
            }
          }}> <span css={style.spanText}> Reserve Fixture </span>  </div>
        </div>

        <div  className="col-md col-6" css={style.fixtureButtonContainer}>
          <div className={currentFixture === "nafc_u18" && "selected"} css={style.fixtureButton} onClick = {(e) => {
            if(currentFixture !== "nafc_u18"){
              showLadder(e, "nafc_u18");
            }
          }}> <span css={style.spanText}> U18 Fixture </span></div>
        </div>

        <div  className="col-md col-6" css={style.fixtureButtonContainer}>
          <div className={currentFixture === "nafc_u16" && "selected"} css={style.fixtureButton} onClick = {(e) => {
            if(currentFixture !== "nafc_u16"){
              showLadder(e, "nafc_u16");
            }
          }}> <span css={style.spanText}> U16 Fixture </span></div>
        </div>

        <div  className="col-md col-6" css={style.fixtureButtonContainer}>
          <div className={currentFixture === "nafc_women" && "selected"} css={style.fixtureButton} onClick = {(e) => {
            if(currentFixture !== "nafc_women"){
              showLadder(e, "nafc_women");
            }
          }}> <span css={style.spanText}> Women&#39;s Fixture </span>  </div>
        </div>

        <div  className="col-md col-6" css={style.fixtureButtonContainer}>
          <div className={currentFixture === "nafc_womendevelop" && "selected"} css={style.fixtureButton} onClick = {(e) => {
            if(currentFixture !== "nafc_womendevelop"){
              showLadder(e, "nafc_womendevelop");
            }
          }}> <span css={style.spanText}> Women&#39;s Development Fixture </span> </div>
        </div>

      </div>
      {
        currentFixtureData ?
        <Fixture fixtureInfo = {currentFixtureData}></Fixture> :
        <Loading></Loading>
      }
    </div>
  )
}

function Fixture(props){
  const fixtureInfo = props.fixtureInfo;
  //console.log("fixtureInfo", fixtureInfo);
  return(
    <div css = {style.fixturesContainer}>
      {
        fixtureInfo.fixture.FixtureRounds.map((item, index) => 
          <div key = {index} className={`${index % 2 === 0 ? "even" : "odd"}`} css={style.fixtureContainer}>
            <div css = {style.fixtureWrapper}>
              <span css = {style.fixtureRoundName}>{item.RoundName}</span>
              <span css = {style.fixtureBar}> | </span>
              <span css = {style.fixtureDate}> {(item.GameDate && item.GameDate.includes("12.00 AM")) ? "TBC" : item.GameDate } </span>
              <span css = {style.fixtureVanue}> {"VENUE : " + item.Venue} </span>
            </div>
            <hr css = {style.hr} />
            <div className="row" style={{marginLeft: '0px', marginRight: '0px'}}>
              <div className="col-6">
                <div css={style.fixtureLogo} style={{backgroundImage: utils.css.bgUrlStyle(item.HomeTeam.TeamImage)}}></div>
                <span css = {style.teamName}> { item.HomeTeam.TeamName }</span>
                {
                  item.ResultHomeScore &&
                  <span css={style.score}> { item.ResultHomeGoals + "."  + item.ResultHomeBehinds} ({item.ResultHomeScore})  </span>
                }
              </div>
              <div className="col-6">
                <div css={style.fixtureLogo} style={{backgroundImage: utils.css.bgUrlStyle(item.AwayTeam.TeamImage)}}></div>
                <span css = {style.teamName}> { item.AwayTeam.TeamName }</span>
                {
                  item.ResultAwayScore &&
                  <span css={style.score}> { item.ResultAwayGoals + "."  + item.ResultAwayBehinds} ({item.ResultAwayScore})  </span>
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}