/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
//import utils from '../../../utils';
//import { Button, usePost, ErrorMessage } from '../../../components';
//import env from '../../../env';
import { useState, Fragment } from 'react';
//import { GetLoginModel } from './loginFormModel';
//import { useForm } from '../../../form';
import { EnterTip } from "../enterTip/view";
import { Profile } from "../profile/view";
import { TipppingResetPassword } from "../resetPassword/view";
import { ReviewStanding } from "../reviewStanding/view";

export function TippingView({tippingSession, logOUt}){ 

  const [currentTab, setCurrentTab] = useState(0);
  return(
    <Fragment>
      <div className="row" css={style.row}>
        
        <div className= "col-4 col-lg col__c">
          <div className= {`col__c__value ${currentTab === 0 && 'selected'}`} onClick={(e) => {
            setCurrentTab(0);
          }}>
            ENTER TIP
          </div>
        </div>

        <div className= "col-4 col-lg col__c">
          <div className= {`col__c__value ${currentTab === 1 && 'selected'}`} onClick={(e) => {
            setCurrentTab(1);
          }}>
            REVIEW STANDING
          </div>
        </div>

        <div className= "col-4 col-lg col__c">
          <div className= {`col__c__value ${currentTab === 2 && 'selected'}`} onClick={(e) => {
            setCurrentTab(2);
          }}>
            PROFILE
          </div>
        </div>

        <div className= "col-4 col-lg col__c">
          <div className= {`col__c__value ${currentTab === 3 && 'selected'}`} onClick={(e) => {
            setCurrentTab(3);
          }}>
            REST PASSWORD
          </div>
        </div>

        <div className= "col-4 col-lg col__c">
          <div className= {`col__c__value ${currentTab === 4 && 'selected'}`} onClick={(e) => {
            logOUt(2);
          }}>
            LOGOUT
          </div>
        </div>
                
      </div>
      {
        currentTab === 0 &&
        <EnterTip tippingSession = {tippingSession} logOUt = {logOUt}></EnterTip>
      }
      {
        currentTab === 1 &&
        <ReviewStanding></ReviewStanding>
      }
      {
        currentTab === 2 &&
        <Profile tippingSession = {tippingSession} logOUt = {logOUt}></Profile>
      }
      {
        currentTab === 3 &&
        <TipppingResetPassword tippingSession = {tippingSession} logOUt = {logOUt}></TipppingResetPassword>
      }
    </Fragment>
  )
}