/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import style from './style';
import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';
import { usePost, SiteLink } from '../../components/';
import env from '../../env';
import { FaSpinner } from "react-icons/fa";
import Slider from "react-slick";

export function NewsFeed(props){ 
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  
  const newsCategory = cmsUtils.payload(item, "NewsCategory");
  const numberToshow = cmsUtils.payload(item, "NumberToshow");

  const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';
  const tileType = "type" + (cmsUtils.payload(item, 'TileType') || "1");

  const cssClass = utils.classNames('cms_item', 'newsFeed', (useSlider && tileType === 'type2') && 'useSlider', item.cssClass || item.anchorName || '');
  //const cssClass = utils.classNames('cms_item', 'pageTiles__sharkCage', (useSlider && tileType === 'type2') && 'useSlider', item.cssClass || item.anchorName || '');
  
  const post = usePost();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  useEffect(() => {
    
    if(!cmsOption.isCmsEdit){
      const value = { id : newsCategory, top : numberToshow};
      post.send(env.apiBase + "/api/proxy/getnewslasttop", value);
    }
    
    // eslint-disable-next-line
  }, []);

  // if (post.done() && sendingStatus === 'pending'){
  // }

  const data = post.response;
  //console.log('data', data);

  if (cmsOption.isCmsEdit){
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> News Feed Item </b>
        </pre>
        </div>
    )
  }

  return(
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={(useSlider && tileType === 'type2') ? style.slider : style.pageTiles }>
      { (data && data.results) ?
        (
          useSlider && tileType === 'type2' ?
          <Slider {...settings} >
            {
              data.results.Result.map((item, index)=><Item newsItem = {item} tileType = {tileType} useSlider = {useSlider} key={index} />)
            }    
          </Slider> :
          data.results.Result.map((item, index)=><Item newsItem = {item} tileType = {tileType} useSlider = {useSlider} key={index} />)
        ) :

        <div css={style.newsFeed_loading} className="newsFeed_loading">
          <FaSpinner className="spinner_loading"></FaSpinner>
        </div>
      }
    </div>
  )
}

function Item(props) {
  
  const newsItem = props.newsItem;
  const tileType = props.tileType;
  const useSlider = props.useSlider;
  
  return (
    <div css={style.pageTile__tile} className={`pageTile__sharkCage ${tileType} ${useSlider && 'useSlider'}`}>
      <SiteLink className="pageTile__sharkCage__content" css={style.tile__content} to={`/newsitem/${newsItem.NewsId}`}>
        <div className="pageTile__sharkCage__bg__wrapper" css={style.tile__bg__wrapper}>
          <div className="pageTile__sharkCage__bg" css={style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle((newsItem.NewsPhotoURL && newsItem.NewsPhotoURL !== '' ? newsItem.NewsPhotoURL : "/-/nafc/Common/Imgs/logo/default-news.jpg")) }}></div>
          {/* <div className="pageTile__sharkCage__bg" css={style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle("https://dummyimage.com/640x360/333333/fff") }}></div> */}
        </div>
        <div className="pageTile__sharkCage__details" css={style.tile__details}>
          <div className="info-wrapper" css={style.info__wrapper}>
            <div className="info-title" css={style.info__title}>
              {newsItem.NewsTitle}
            </div>
            <div className="info-des" css={style.info__des}>
              <div className="infoDesc" css={style.infoDesc}>
                { newsItem.NewsDescription }
              </div>
            </div>
            <div className="info-btn" css={style.info__btn}>
              FIND OUT MORE &gt;
            </div>
          </div>
        </div>
      </SiteLink>
    </div>
  )
}

// function Item({newsItem}){
//   //console.log('New item', newsItem);
//   //console.log('2222', utils.date.formatDDMMYYYY(newsItem.NewsDate));
//   const [openContent, setOpenContent] = useState(false);
  
//   return(
//     <div css={style.container} className="row">
//       <div className="col-sm-3">
//         <img css={style.thumbnail_image} src={'http://nafc.com.au' + newsItem.NewsPhotoURL} alt = "thumbnail1" />
//       </div>
//       <div className="col-sm-9">
//          <div css={style.news_title} className="news_title">
//            {newsItem.NewsTitle}
//          </div>
//          <div css={style.news_date} className="news_date">
//            {utils.date.formatDDMMYYYY(newsItem.NewsDate)}
//          </div>
//          <div css={style.news_des} className="news_des">
//            {newsItem.NewsDescription}
//          </div>
//          <div>
//            <Button onClick={(e) => {
//              setOpenContent(!openContent);
//            }}>READ MORE +</Button>
//          </div>
//          {
//            openContent &&
//             <div css={style.news_content} className="news_content" id = {'news_content_' + newsItem.NewsId}>
//               <img src={'http://nafc.com.au' + newsItem.NewsPhotoURL} alt = "thumbnail2" />
//               <div dangerouslySetInnerHTML={{ __html: newsItem.Content }}></div>
//             </div>
//          }
//       </div>
//     </div>
//   )
// }