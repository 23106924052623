/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { createPortal } from "react-dom";
import style from './style';

function Portal({children}){
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
      let mount = document.getElementById("portal-root");
      mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function PlayerPopUpView(props){

  const popupItem = props.popupItem;

  //const [cOrientationLand, setCOrientationLand] = useState(false);

  // useLayoutEffect(() => {
    
  //   // if(window.screen.orientation.type === "landscape-primary" && window.screen.orientation.angle === 90){
  //   //   setCOrientationLand(true);
  //   // }

  //   // window.screen.orientation.addEventListener('change', () => {
  //   //   setCOrientationLand(false);
  //   //   if(window.screen.orientation.type === "landscape-primary" && window.screen.orientation.angle === 90){
  //   //     setCOrientationLand(true);
  //   //   }
  //   // });

  //   // window.screen.orientation.addEventListener('change', function(){
  //   //   console.log("sfdsfds", window.screen.orientation);
  //   // });

  //   // eslint-disable-next-line
  // }, []);

  //${cOrientationLand ? 'land' : ""}
  return(
    <Portal>
        <div css={style.popupContainer}>
            <div className={`item`}>
              <div className="closeButton" onClick={() => {
                props.setOpenPopup(false);
                document.body.style.overflow = "unset";
              }}>
                CLOSE
              </div>
              <div css={style.popup__item__wrapper}>
                <h4 style={{marginBottom: '0.2rem'}}> {popupItem.jumperNoText} </h4>
                
                {
                  (popupItem.imageUrl && popupItem.imageUrl !== '') &&
                  <img css={style.__image} src={popupItem.imageUrl} alt = "pro" />
                }
              
                <div className="popup__item__container" css={style.popup__item_container} dangerouslySetInnerHTML={{ __html: popupItem.playerBio }}></div>
              </div>  
            </div>
          </div>
    </Portal>
  )
}

//
// 