/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useMemo, useEffect, Fragment, useState } from "react";
import style from "./style";
import utils from "../../utils";
import { createPortal } from "react-dom";
import env from "../../env";
import { SiteLink } from "../../components";
import { MdClose } from "react-icons/md";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function JumperList(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "jumper__list",
    item.cssClass || item.anchorName || ""
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [currentGuernsey, setCurrentGuernsey] = useState();
  const [lists, setLists] = useState();

  const items = useMemo(() => {
    const _items = [];
    for (let i = 1; i <= 60; i++) {
      _items.push({
        id: i,
        imgUrl: `/assets/jersey/nafc_jersey_${i}.png`,
      });
    }
    return _items;

    //eslint-disable-next-line
  }, []);

  function openPopupFc(guernseyId) {
    setCurrentGuernsey(guernseyId);
    setOpenPopup(true);
    document.body.style.overflow = "hidden";
  }

  function closePopupFc() {
    setOpenPopup(false);
    document.body.style.overflow = "unset";
  }

  useEffect(() => {
    if (!currentGuernsey) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const res = await fetch(
        env.apiBase + `/api/footy/getjumplist/${currentGuernsey}`,
        signal
      );
      const data = await res.json();

      if (data && data.results && data.results.lists) {
        setLists(data.results.lists);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };

    //eslint-disable-next-line
  }, [currentGuernsey]);

  return (
    <Fragment>
      <Portal>
        {openPopup && (
          <div css={style.popupContainer} onClick={closePopupFc}>
            <PopUpItemView lists={lists} currentGuernsey={currentGuernsey} closePopupFc = {closePopupFc} />
          </div>
        )}
      </Portal>

      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        css={style.jumper__list}
      >
        {items.map((item) => {
          return (
            <JumperItem key={item.id} item={item} openPopupFc={openPopupFc} />
          );
        })}
      </div>
    </Fragment>
  );
}

function JumperItem({ item, openPopupFc }) {
  return (
    <div className="jumper__list__item" css={style.jumper__list__item}>
      <div
        className="jumper__list__item__img__con"
        onClick={() => {
          openPopupFc(item.id);
        }}
      >
        <img width="100%" src={`${item.imgUrl}`}  alt="jumper_img"/>
      </div>
    </div>
  );
}

function PopUpItemView({ lists, currentGuernsey, closePopupFc }) {
  return (
    <div css={style.popUpItemView}>
      <div style={{position : 'relative'}}>
        <MdClose size={30} css = {style.close__button} onClick={closePopupFc} />
        <div css={style.popup__item__top__title}>
          {`GUERNSEY ${currentGuernsey}`}
        </div>
        <div css={style.popup__item}>
          <div css={style.popup__item__title}>
            {`Most Games and Goals by Players in GUERNSEY ${currentGuernsey}`}
          </div>

          {lists && (
            <div css={style.popup__item__co}>
              <div css={style.popup__item__list}>
                <div className="top__item" css={style.popup__item__list__item}>
                  <div className="name" style={{fontWeight: 700}}>Player</div>
                  <div className="value" style={{fontWeight: 700}}>Games</div>
                </div>

                {lists.count.map((item, index) => {
                  return (
                    <div key={index} css={style.popup__item__list__item}>
                      <div className="name">
                        <SiteLink to={`/history/search?type=player&id=${item.playerId}`}>
                          {item.surname +
                            (item.otherNames && item.otherNames !== ""
                              ? ", " + item.otherNames
                              : "")}
                        </SiteLink>
                      </div>
                      <div className="value">{item.count} </div>
                    </div>
                  );
                })}
              </div>

              <div css={style.popup__item__list} className="right">
                <div
                  className="top__item right"
                  css={style.popup__item__list__item}
                >
                  <div className="name" style={{fontWeight: 700}}>Player</div>
                  <div className="value" style={{fontWeight: 700}}>Goals</div>
                </div>

                {lists.gola.map((item, index) => {
                  return (
                    <div
                      className="right"
                      key={index}
                      css={style.popup__item__list__item}
                    >
                      <div className="name">
                        <SiteLink to={`/history/search?type=player&id=${item.playerId}`}>
                          {item.surname +
                            (item.otherNames && item.otherNames !== ""
                              ? ", " + item.otherNames
                              : "")}
                        </SiteLink>
                      </div>
                      <div className="value">{item.goals} </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
