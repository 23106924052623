/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';

export function ConvertTable(props){
  const values = props.value.split(";;");
  return(
    <div className="row" style = {{paddingLeft: '10px', paddingRight: '10px'}}>
      { values.map((item, index) => {
        const _values = item.split(":");
        return(
          <div css = {style.item__container} key = {index} className="col-6 col-sm-4">
            <span css = {style.itme__span1}> {_values[0].trim() + ":"} </span>
            <span css = {style.itme__span2}> {_values[1].trim()} </span>
          </div>
        )
      })}
    </div>
  )
}