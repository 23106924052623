import { mq, variables } from '../../cssInJs'

export default{

  team__selection__container : {
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    'button' : {
      fontWeight: 600
    }
  },
  title : mq({
    fontSize: ['1.2rem', null, '2rem'],
    color: variables.darkRecColor
  }),
  typeTitle : mq({
    fontSize: ['1.2rem', null, '1.5rem'],
    color: variables.darkRecColor
  }),
  team__selection : mq({
    backgroundImage: 'url(/core/footy/imgs/ground.png)',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    width: ['100%', null, '402px'],
    height: ['100%', null, '468px'],
    margin: '0 auto',
    marginTop: '1rem',
    marginBottom: '1rem',
    'table' : {
      width: '100%',
      height: '100%'
    },
    'td' : mq({
      height: '80px',
      textAlign: 'center',
      fontSize: '13px',
      fontWeight: 'bold',
      padding: '1px',
      width: '120px',
      color: '#000'
    }),

    '&.women, &.womendevelopment' : {
      '.forward_pocket_left, .back_pocket_left' : {
        marginRight:'-60% !important'
      },
      '.forward_pocket_right, .back_pocket_right' : {
        marginLeft: '-60% !important'
      }
    }

  }),
  player1__name : {
    color: variables.fontColor
  },
  player2__title : {
    fontSize: '0.8rem',
    fontWeight: '600'
  },
  player2__name : {
    marginRight: '0.5rem',
    fontSize: '0.8rem',
    '&.last' :{
      marginRight: '0rem',
    }
  }
}