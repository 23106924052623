import { padding } from "polished";
import { variables, mq } from "../../cssInJs";

export default {
  jumper__list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    //gap : '1rem'
  },

  jumper__list__item: mq({
    flex: ["1 1 20%",null,"1 1 10%"],
    maxWidth: ["20%",null, "10%"],
    cursor: "pointer",
    padding: "10px",
    ".jumper__list__item__img__con": {},
  }),

  popupContainer: {
    backgroundColor: "rgba(0,0,0,0.8)",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: "9998",
  },
  popUpItemView : {
    position : 'absolute',
    left: '50%',
    top : '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    //padding: '1rem',
    width: '90%',
    maxWidth : '980px',
    //overflow: 'hidden',
  },
  popup__item__top__title : mq({
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    fontSize: ['1.1rem',null,'1rem'],
    fontWeight: 700,
    fontFamily: variables.familyHeader,
    color: '#ed1c24',
    padding: '0.25rem 20px',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'top left',
    position : 'relative',
    backgroundImage: 'url(https://www.redlegsmuseum.com.au/site/Redlegsmuseum/skins/default/img/sidebar-bg.png)'
  }),
  popup__item : {
    position : 'relative',
    paddingBottom: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)'
  },
  close__button : {
    position : 'absolute',
    zIndex: 1,
    color: 'white',
    top: '-30px',
    right: '20px',
    cursor: 'pointer',
  },

  popup__item__title : mq({
    fontWeight: 700,
    fontSize: ['1rem',null,'0.8rem'],
    textAlign: 'center',
    backgroundColor : 'rgb(220, 220, 220)',
    padding: '0.25rem 0.5rem'
  }),

  popup__item__co : {
    display: 'flex',
    flexDirection : 'row',
    flexWrap : 'wrap',
    maxHeight: '560px',
    //height: '60vh',
    overflow: 'auto',
    //backgroundColor: 'rgba(255, 255, 255, 0.9)',
    '@media (orientation: landscape)' : {
      height: '60vh',
    }
  },

  popup__item__list : mq({
    flex: ['1 1 99%',null,'1 1 50%'] ,
    maxWidth: ['99%',null,'50%'] ,
    '&.right' : mq({
      borderLeft: ['unset',null,'1px solid black'] 
    })
  }),
  popup__item__list__item : mq({
    display: 'flex',
    flexWrap : 'wrap',
    fontSize: ['1rem',null,'0.8rem'],
    //backgroundColor: 'white',
    '&.right' : {
      //borderLeft: '1px solid black'
    },
    '&.top__item' : {
      position : 'sticky',
      top: '-2px',
      backgroundColor : 'rgb(240, 240, 240)'
    },

    '& > div' : {
      padding: '0.25rem 1rem',
      '&.name' : {
        flex: '2 1 auto',
        'a' : {
          color: variables.fontColor,
          '&:hover' : {
            color: variables.primaryColor,
          }
        }
        //maxWidth: '60%',
      },
      '&.value' : {
        //flex: '1 1 40%',
        //maxWidth: '40%',
      }
    }
  })
};
