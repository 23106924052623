/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../utils';
//import { Loading } from '../../components/';
//import env from '../../env';
import { useState, useLayoutEffect } from 'react';
import { TipppingLogin } from './login/view';
import { TippingView } from './tippingView/view';
import { Fragment } from 'react';
import { TipppingSignUp } from './signUp/view';
import { TipppingForgetPassword } from './forgetPassword/view';

export function Tipping(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'tipping', item.cssClass || item.anchorName || '');
  const [tippingSession, setTippingSession] = useState();
  const [swtitchForm, setWwtitchForm] = useState(0);

  useLayoutEffect(() => {
    setTippingSession(sessionStorage.getItem("tippingsession"));
  }, []);

  if (cmsOption.isCmsEdit){
    return(
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Tipping </b>
        </pre>
      </div>
    )
  }

  function logOUt(type = 1){
    if(type === 1){
      setTimeout(() => {
        setTippingSession(null);
        sessionStorage.removeItem("tippingsession");
      }, 1000);
    }
    else{
      setTippingSession(null);
      sessionStorage.removeItem("tippingsession");
    }
  }

  return(
    <div style={{marginTop:'1rem', marginBottom:'1rem'}} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}> 
      {
        (tippingSession && tippingSession != null ) ?
        <TippingView tippingSession = {tippingSession} logOUt = {logOUt}></TippingView> :
        <Fragment> 
          {
            swtitchForm === 0 &&
            <Fragment>
              <TipppingLogin setTippingSession = {setTippingSession}></TipppingLogin>
            <div style={{marginTop:'0.5rem'}}>
              Forgot your password? <span css={style.span__link} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setWwtitchForm(2);
              }}> Click Here </span> Or
              <div style={{marginTop:'0.5rem'}}>
                <button className="btn btn-primary" onClick={(e) => {
                  setWwtitchForm(1);
                }}> REGISTER FOR FOOTY TIPPING  </button>
              </div> 
            </div>
            </Fragment>
          }
          {
            swtitchForm === 1 &&
            <TipppingSignUp setTippingSession = {setTippingSession} setWwtitchForm = {setWwtitchForm}></TipppingSignUp>
          }
          {
            swtitchForm === 2 &&
            <TipppingForgetPassword setWwtitchForm = {setWwtitchForm}></TipppingForgetPassword>
          }
        </Fragment> 
      }
    </div>
  )
}