import utils from "../utils";
import env from '../env';

export const isMobile = () => {
  var width = utils.css.screenWidth()
  return width < 768
}

export function resourcePath(url){
  return utils.url.isExternalLink(url)? url : env.resourceBase + url;
}

export function fullUrl(url){
  return utils.url.isExternalLink(url)? url : env.baseOrigin + url;
}

export const isNZ = env.locale === 'nz';



const lsVer = "V2";
const lsSearchInfo = "lsSearchInfo" + lsVer;

function createLsSearchInfo(){
  const lsSearchInfoData = {
    backButtonCheck : false,
    backButtonCheck2 : false,
    //checkBackupResult : false,
    oldUrls : [],
    //backBtnCount : 0,
    searchResult : null
  };
  localStorage.setItem(lsSearchInfo, JSON.stringify(lsSearchInfoData));
}

export function setupLsForSearch(storeItem){

  createLsSearchInfo();
  const searchResultData = JSON.parse(localStorage.getItem(lsSearchInfo));

  searchResultData.backButtonCheck = true;
  searchResultData.searchResult = storeItem;
  searchResultData.oldUrls.push("/history");
  
  localStorage.setItem(lsSearchInfo, JSON.stringify(searchResultData));
}

export function setupLsForSearchUrl(url){

  if(!localStorage.getItem(lsSearchInfo)){
    createLsSearchInfo();
  }
  const searchResultData = JSON.parse(localStorage.getItem(lsSearchInfo));
  searchResultData.backButtonCheck = true;
  searchResultData.oldUrls.push(url);
  localStorage.setItem(lsSearchInfo, JSON.stringify(searchResultData));
}

export function reSetupLsForSearch(){

  var returnResult;
  const searchResultData = localStorage.getItem(lsSearchInfo) ? JSON.parse(localStorage.getItem(lsSearchInfo)) : null;
  
  if(searchResultData && searchResultData.backButtonCheck === true){
    returnResult = searchResultData.oldUrls[searchResultData.oldUrls.length -1];
  }
  //console.log(searchResultData.oldUrls.length);
  return returnResult;
}

export function backBtnCheckInResult(){
  const searchResultData = localStorage.getItem(lsSearchInfo) ? JSON.parse(localStorage.getItem(lsSearchInfo)) : null;
  searchResultData.backButtonCheck2 = true;
  searchResultData.oldUrls.pop();
  localStorage.setItem(lsSearchInfo, JSON.stringify(searchResultData));
}

export function getStoreInfo(){

  const result = {
    checkBackupResult : false,
    filter : null,
    games : null,
    players : null
  };
  
  const searchResultData = localStorage.getItem(lsSearchInfo) ? JSON.parse(localStorage.getItem(lsSearchInfo)) : null;

  if(window.location.pathname === "/history"){
    if(searchResultData){
      if(searchResultData.backButtonCheck2 === true){
          result.filter = searchResultData.searchResult.Filter;
          result.games = searchResultData.searchResult.Games;
          result.players = searchResultData.searchResult.Players;
          result.checkBackupResult = result.filter ? true : false;
          createLsSearchInfo();
      }
    }
  }
  return result;
}

// export function removeCheckBackupResult(){
//   const searchResultData = localStorage.getItem(lsSearchInfo) ? JSON.parse(localStorage.getItem(lsSearchInfo)) : null;
//   if(searchResultData){
//     searchResultData.checkBackupResult = false;
//     localStorage.setItem(lsSearchInfo, JSON.stringify(searchResultData));
//   }
// }

// export function getStoreData(){
//   const searchResultData = localStorage.getItem(lsSearchInfo) ? JSON.parse(localStorage.getItem(lsSearchInfo)) : null;
//   if(searchResultData){
//     return searchResultData.searchResult;
//   }
// }