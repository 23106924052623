/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRouter, useGet, NotFound, ErrorPopup, MetaTag } from '../../components';
import env from '../../env';
import { useEffect, Fragment, useState } from 'react';
import utils from '../../utils';
import style from './style';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
import { FaSpinner } from "react-icons/fa";

export function NewsView(props){

  const { query } = useRouter();
  const newsId = query.id || '';
  const [loaded, setLoaded] = useState(false);
  console.log('newsid', newsId);

  const get = useGet();

  useEffect(() => {
    get.send(env.apiBase + `/api/news/getnewsitem/${newsId}`);
    setLoaded(false);

    // eslint-disable-next-line
  }, []);

  if(get.loading()){
    return (
      <div css={style.loading}>
        <FaSpinner className="spinner_loading"></FaSpinner>
      </div>
    )
  }
  else if(get.hasErrors()){
    return(
      <ErrorPopup title="Loading Error" errors={get.errors} />
    )
  }

  if(get.done() && !loaded){
    setLoaded(true);
  }

  const dataResulst = get.response;

  return(
    <NewsPage data = {dataResulst}></NewsPage>
  )
}

function NewsPage(props){
  const newsData = (props.data.results && props.data.results.Result) ? props.data.results.Result : null;
  const newsDate = (newsData && (newsData.newsDate && newsData.newsDate !== '')) ?  newsData.newsDate.split(" ")[0] : "";
  return(
    <Fragment>
      {
        newsData ? 
        <Fragment>
        <MetaTag data={getMetaTag(props.data.results.Result)} />
        <div className = "news__page">
          <div css = {style.banner__image} className = "cms_item' 'single-banner' 'banner-item bannerSize--standard" 
            style={{ backgroundImage: utils.css.bgUrlStyle(newsData.newsPhotoURL) }}>
          </div>
          <div className="container" css = {style.page__container}>
            <h1> {newsData.newsTitle} </h1>
            <div css = {style.news__date}> { newsDate } </div>
            <div dangerouslySetInnerHTML={{ __html: newsData.content }}></div>
          </div>
        </div>
        </Fragment> : <NotFound></NotFound>
      }
      <ViewPartial />
    </Fragment>
  )
}

function getMetaTag(pageData) {
  
  console.log("pageData", pageData);

  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;

  return {
    title: pageData.newsTitle + ' | ' + titleSuffix,
    description: pageData.newsDescription,
    keywords: '',
    heroImageUrl: utils.site.fullUrl(pageData.newsPhotoURL),
    language: 'English',
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl("/newsitem/" + pageData.newsId),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }
}