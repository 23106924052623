/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import { useState, useEffect, Fragment } from 'react';
import utils from '../../utils';
import env from '../../env';
import FsLightbox from 'fslightbox-react';
import { createPortal } from "react-dom";

function Portal({children}){
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
      let mount = document.getElementById("portal-root");
      mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function PhotoGalleryNafc(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'photo__gallery__nafc', item.cssClass || item.anchorName || '');
  const [photogallerylist, setPhotogallerylist]  = useState();
  const [thumbnails, setThumbnails] = useState({});
  const [getPhotoGalleryId, setGetPhotoGalleryId] = useState("");
  const [images, setImages] = useState();
  const [toggler, setToggler] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);

  useEffect(() => {
    if (cmsOption.isCmsEdit){
      return;
    }

    const fetchData = async () => {
      const url = "/api/proxy/getphotogallerylist";
      const res = await fetch(env.apiBase + url);
      const data = await res.json();
      
      if(data.errors && data.errors.length > 0){

      }
      else{
        const set = data.results.Result.set;
      //console.log("set", set);
        if(set && set.length > 0){
          setPhotogallerylist({ latest : set[0], list : set.slice(1) });
        }
      }

      
    };
    
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cmsOption.isCmsEdit || getPhotoGalleryId === ""){
      return;
    }

    const fetchData = async () => {

      const url = "/api/proxy/GetPhotoGallery/" + getPhotoGalleryId;
      const res = await fetch(env.apiBase + url);
      const data = await res.json();
      const resource = data.results.Result.resource;
      const images = [];
      console.log("data", resource);

      for(let i in resource){
        //const item = {...resource[i], index : parseInt(images.length) + parseInt(i)};
        //_resource.push(item);
        images.push(resource[i].ImageUrl);
      }

      setThumbnails({
        ...thumbnails,
         ["g_" + getPhotoGalleryId] : {
            openStatus : true,
            resource : resource,
            images : images
          }
      });
      //setImages(images.concat(resource));
    };
    
    fetchData();
    setGetPhotoGalleryId("");
    // eslint-disable-next-line
  }, [getPhotoGalleryId]);

  if (cmsOption.isCmsEdit){
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Photo Gallery Nafc </b>
        </pre>
      </div>
    )
  }

  return(
    <div css={style.photoGallery} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      
      <Portal>
        <div style={{display:'none'}}>
                    PhotoGallery
        </div>
        <FsLightbox
          toggler={ toggler }
          sources={images}
          sourceIndex={popupIndex}
        />  
      </Portal>

      {
        photogallerylist &&
        <Fragment>
          
            <h4>{photogallerylist.latest.GalleryTitle}</h4>
            <button style={{marginTop:'0.5rem'}} className = "btn btn-primary" onClick={((e) => {
              if(!thumbnails["g_" + photogallerylist.latest.GalleryId]){
                setGetPhotoGalleryId(photogallerylist.latest.GalleryId);
              }
              else{
                //setThumbnails({...thumbnails, [thumbnails["g_" + photogallerylist.latest.GalleryId].openStatus] : false });
                //setThumbnails({...thumbnails, [thumbnails["g_" + photogallerylist.latest.GalleryId]] : {...[thumbnails["g_" + photogallerylist.latest.GalleryId]], [thumbnails["g_" + photogallerylist.latest.GalleryId].openStatus] : ![thumbnails["g_" + photogallerylist.latest.GalleryId].openStatus] }}); 
                setThumbnails(pre => {
                  let _new = {...pre};
                  _new["g_" + photogallerylist.latest.GalleryId].openStatus = !_new["g_" + photogallerylist.latest.GalleryId].openStatus;
                  return _new
                });
              }
            })} > { (thumbnails["g_" + photogallerylist.latest.GalleryId] && thumbnails["g_" + photogallerylist.latest.GalleryId].openStatus === true) && "CLOSE " }   LATEST PHOTO GALLERY </button>
            {
              (thumbnails && thumbnails["g_" + photogallerylist.latest.GalleryId]) &&
              <div css={style.thumbnails} className = {`${thumbnails["g_" + photogallerylist.latest.GalleryId].openStatus === true ? "open" : ""}`}>
                {
                    thumbnails["g_" + photogallerylist.latest.GalleryId].resource.map((item, index) =>
                     <div css={style.thumbnail} className="thumbnail" key={index} onClick={(e)=> {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        setImages(thumbnails["g_" + photogallerylist.latest.GalleryId].images);
                        setToggler(!toggler);
                        setPopupIndex(index);
                        
                        //   setTimeout(() => {
                          
                        // }, 200); 
                     }}>
                         <div css={style.bg} style={{ backgroundImage: utils.css.bgUrlStyle(item.ThumbnailImageUrl) }}></div>
                     </div>
                    ) 
                }
            </div>
            }
          
        </Fragment>
      }
      {
        (photogallerylist && photogallerylist.list.length > 0) &&
        <Fragment>
          <h4 style={{marginTop: '1rem'}}> VIEW PHOTO GALLERY </h4>
          {
            photogallerylist.list.map((item, index) =>
              <div key={index}>
                <button style={{marginTop:'0.5rem', marginBottom: '0.5rem'}} className = "btn btn-primary" onClick={(e) => {
                  if(!thumbnails["g_" + item.GalleryId]){
                    setGetPhotoGalleryId(item.GalleryId);
                  }
                  else{
                    setThumbnails(pre => {
                      let _new = {...pre};
                      _new["g_" + item.GalleryId].openStatus = !_new["g_" + item.GalleryId].openStatus;
                      return _new
                    });
                  }
                }}> { ((thumbnails["g_" + item.GalleryId] && thumbnails["g_" + item.GalleryId].openStatus === true) ? "CLOSE " : "" )  + item.GalleryTitle} </button>
                {
                  (thumbnails && thumbnails["g_" + item.GalleryId]) &&
                  <div css={style.thumbnails} className = {`${thumbnails["g_" + item.GalleryId].openStatus === true ? "open" : ""}`}>
                    {
                      thumbnails["g_" + item.GalleryId].resource.map((resourceitem, index) => 
                        <div css={style.thumbnail} className="thumbnail" key={index} onClick = {(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          setImages(thumbnails["g_" + item.GalleryId].images);
                          setToggler(!toggler);
                          setPopupIndex(index);
                          
                        }}>
                          <div css={style.bg} style={{ backgroundImage: utils.css.bgUrlStyle(resourceitem.ThumbnailImageUrl) }}></div>
                        </div>
                      )
                    }
                  </div>
                }
              </div>  
            )
          }
        </Fragment>
      }
    </div>
  )

}