/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import { GetResetPasswordModel } from './resetPasswordFormModel';
import { useForm } from '../../../form';
import { Button, usePost, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useState } from 'react';

export function TipppingResetPassword({tippingSession, logOUt}){

  const model = GetResetPasswordModel({});
  const form = useForm(model, { usePlaceholder: true });
  const render = (name) => form.renderControl(name, null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const post = usePost();

  const onSubmit = ((e) => {
    form.validateForm(e, () => {
      const formData = { ...form.getValue(), token : tippingSession };
      post.send(env.apiBase + "/api/proxy/tippingresetpassword", formData);
      setSendingStatus('pending');
    });
  });

  if (post.done() && sendingStatus === 'pending') {
    //const _data = post.response;
    form.reset(model);
    setSendingStatus(null);
  }

  if(post.hasErrors()){
    let checkSessionExpired = false;
    for(let i in post.errors){
      if(post.errors[i] === "session expired"){
        checkSessionExpired = true;
        break;
      }
    }

    setTimeout(() => {
      setSendingStatus(null);
      if(checkSessionExpired === true){
        logOUt();
      }
    }, 1000);
  }

  return(
    <div className="tipping__reset__password__form" style={{marginTop:'1rem'}}>
      <h4 style={{marginBottom: '0.5rem'}}> Reset Password </h4>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('password')}
        </div>
        <div className="col-12 col-md-6">
          {render('newPassword')}
        </div>
        <div className="col-12" style={{textAlign:'center'}}>
          
          <div className="actions" css={style.button__container}>
            <Button onClick={onSubmit} status={post.status}> SUBMIT </Button>
          </div>
          
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
          {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}> Successfully Save!</span>}

        </div>
      </div>
    </div>
  )

}