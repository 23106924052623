/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../utils';
//import { Loading } from '../../components/';
import env from '../../env';
import { useEffect, useState } from 'react';
import cmsUtils from '../../cms/utils/cmsUtils';
import { Fragment } from 'react';

export function UpComingGame(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'fixtures', item.cssClass || item.anchorName || '');
  const gameGroup = cmsUtils.payload(item, 'GameGroup') || "nafc";
  const [fixtures, setFixtures] = useState();

  useEffect(() => {
    if (cmsOption.isCmsEdit){
      return;
    }

    const fetchData = async () => {
      const url = "/api/proxy/getfixture/" + gameGroup;
      const res = await fetch(env.apiBase + url);
      const data = await res.json();
      if(data.errors && data.errors.length > 0){
      }
      else{
        var fixtures = data.results.Result.FixtureRounds;
        const today = new Date();
      var upComingGame = null;
      var latestGame = null;
      fixtures.forEach(el => {
        const date = new Date(el.GameDateValue);
        
        if(upComingGame){
          const _up = new Date(upComingGame.GameDateValue);
          if(date.getTime() <= _up.getTime()){
            upComingGame = el;
          }
        }
        else{
          if(today.getTime() <= date.getTime() ){
            upComingGame = el;
          }
        }

        if(today.getTime() >= date.getTime() ){
          latestGame = el;
        }
        
      });
      setFixtures({upComingGame : upComingGame, latestGame : latestGame});
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  if (cmsOption.isCmsEdit){
    return(
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc', marginBottom: '1rem', marginTop: '1rem' }}>
          <b> Up Coming Game : { gameGroup } </b>
        </pre>
      </div>
    )
  }


  return(
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {
        (fixtures && fixtures.upComingGame) &&
        <Fragment>
          <h4 style={{textAlign:'center'}}>
            { gameGroup === "nafc" ? "Upcoming SANFL Match"  : "Upcoming SANFLW Match"}
          </h4>
          <Fixture fixtureInfo = {fixtures.upComingGame}></Fixture>
        </Fragment>
      }
      {
        (fixtures && fixtures.latestGame) &&
        <Fragment>
          <h4 style={{textAlign:'center'}}>
            { gameGroup === "nafc" ? "Latest SANFL Match"  : "Latest SANFLW Match"}
          </h4>
          <Fixture fixtureInfo = {fixtures.latestGame}></Fixture>
        </Fragment>
      }
    </div>
  )

}

function Fixture({ fixtureInfo }){

  return(
    <div css = {style.fixturesContainer}>
      <div css={style.fixtureContainer} className="even">
            <div css = {style.fixtureWrapper} >
              <span css = {style.fixtureRoundName}>{fixtureInfo.RoundName}</span>
              <span css = {style.fixtureBar}> | </span>
              <span css = {style.fixtureDate}> { (fixtureInfo.GameDate && fixtureInfo.GameDate.includes("12.00 AM")) ? "TBC" : fixtureInfo.GameDate} </span>
              <span css = {style.fixtureVanue}> {"VENUE : " + fixtureInfo.Venue} </span>
            </div>
            <hr css = {style.hr} />
            <div className="row" style={{marginLeft: '0px', marginRight: '0px'}}>
              <div className="col-6">
                <div css={style.fixtureLogo} style={{backgroundImage: utils.css.bgUrlStyle(fixtureInfo.HomeTeam.TeamImage)}}></div>
                <span css = {style.teamName}> { fixtureInfo.HomeTeam.TeamName }</span>
                {
                  fixtureInfo.ResultHomeScore &&
                  <span css={style.score}> { fixtureInfo.ResultHomeGoals + "."  + fixtureInfo.ResultHomeBehinds} ({fixtureInfo.ResultHomeScore})  </span>
                }
              </div>
              <div className="col-6">
                <div css={style.fixtureLogo} style={{backgroundImage: utils.css.bgUrlStyle(fixtureInfo.AwayTeam.TeamImage)}}></div>
                <span css = {style.teamName}> { fixtureInfo.AwayTeam.TeamName }</span>
                {
                  fixtureInfo.ResultAwayScore &&
                  <span css={style.score}> { fixtureInfo.ResultAwayGoals + "."  + fixtureInfo.ResultAwayBehinds} ({fixtureInfo.ResultAwayScore}) </span>
                }
              </div>
            </div>
          </div>
    </div>
  )
}