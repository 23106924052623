/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import { useForm } from '../../../form';
import { Button, usePost, ErrorMessage } from '../..';
import env from '../../../env';
import { useState } from 'react';
import { GetForgetPasswordModel } from './forgetPasswordFormModel';

export function TipppingForgetPassword({setWwtitchForm}){
  
  const model = GetForgetPasswordModel({});
  const form = useForm(model, { usePlaceholder: true });
  const render = (name) => form.renderControl(name, null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const post = usePost();

  const onSubmit = ((e) => {
    form.validateForm(e, () => {
      const formData = { ...form.getValue() };
      post.send(env.apiBase + "/api/proxy/tippingpasswordrecovery", formData);
      setSendingStatus('pending');
    });
  });
  
  if (post.done() && sendingStatus === 'pending') {
    const _data = post.response;
    console.log("_data", _data);
    setTimeout(() => {
      //sessionStorage.setItem("tippingsession", _data.results.tippingSessionInfo.Result.token);
      //setTippingSession(_data.results.tippingSessionInfo.Result.token);
    }, 1000);
    setSendingStatus(null);
  }
  
  return(
    <div className="tipping__reset__password__form">
      <div> Forgot Your Password? </div>
      <div style={{marginBottom: '1rem'}}> Enter your email address to receive your password reset instructions. </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('email')}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="actions" style={{textAlign:'left', margin:"0"}} css={style.button__container}>
            <Button onClick={onSubmit} status={post.status}> SUBMIT </Button>
          </div>
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
          {post.done() && 
          
          <div style={{color:'red', fontWeight: 'bold', marginTop:'0.5rem'}}>
            Successfully reset. A new password has been sent to your email address.
          </div>}

          <div style={{marginTop:"1rem"}}>Or <span css={style.span__link} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setWwtitchForm(1);
          }}> Sign up now</span> </div>

          <div>
              <span css={style.span__link} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setWwtitchForm(0);
              }}> Return to login page  </span>
          </div>

        </div>
      </div>

    </div>
  )
}