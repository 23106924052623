import { variables, mq } from '../../../cssInJs';

export default{
  row : {
    marginRight: '0px',
    marginLeft: '0px',

    '.col__c' : {
      paddingLeft: '0px',
      paddingRight: '0px',
      //marginLeft: '2px',
      //marginRight: '2px',
    },

    '.col__c__value' : mq({
      backgroundColor: variables.greyColor,
      textAlign: 'center',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      fontWeight: 600,
      cursor: 'pointer',
      fontSize: ['0.7rem', null, '1rem'],
      margin: '1px',
      '&.selected' : {
        backgroundColor: variables.redColor,
        color: 'white'
      }
    })

  }
}