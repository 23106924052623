/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, Fragment, useState } from 'react';
import style from './style';
import utils from '../../utils';
import { useGet } from '../../components';
import { FaSpinner } from "react-icons/fa";
import env from '../../env';
//import { SiteLink } from '../../lib/router';
import { getSearchGameYearModel } from './gameSearchGameYearModel';
import { useForm } from '../../form';
//import { ViewPartial } from '../../cms/pages/partials/viewPartial';

export function HistoryPlayerView(props){

  //const { query } = useRouter();
  //const playerId = query.playerid || '';
  const playerId = props.playerId;
  //const gameId = "abc";
  const get = useGet();

  //console.log('gameId', gameId);

  useEffect(() => {
    get.send(env.apiBase + `/api/proxy/getplayer/${playerId}`);

    // eslint-disable-next-line
  }, []);

  const dataResulst = get.response;
  console.log('reslut', dataResulst);

  return(
    dataResulst && dataResulst.results && dataResulst.results.Result !== undefined ? <PlayerView data = {dataResulst} playerId = {playerId}></PlayerView> : 
    <div css={style.loading}>
      <FaSpinner className="spinner_loading"></FaSpinner>
    </div>
  )

}

function PlayerView(props) {

  //const playerData = props.data.results.Result === undefined ? null : props.data.results.Result;
  
  const playerData = props.data.results.Result;
  const playerId = props.playerId;
  
  const totalGamesGoals = {
    totalLequgeGames : 0, totalOtherGames : 0, totalLeagueGoals : 0, totalOtherGoals : 0
  }

  const years = [];

  let checkYear = "";
  playerData.Games.forEach(el => {

    if(el.CompetitionType === "League"){
      totalGamesGoals.totalLequgeGames ++;
      totalGamesGoals.totalLeagueGoals = totalGamesGoals.totalLeagueGoals + el.Goals;
    }
    else{
      totalGamesGoals.totalOtherGames ++;
      totalGamesGoals.totalOtherGoals = totalGamesGoals.totalOtherGoals + el.Goals;
    }

    if(checkYear !== el.Year){
      checkYear = el.Year;
      years.push({name : el.Year, value : el.Year});
    }

  });
  const model = getSearchGameYearModel(years);
  const searchGameYearForm = useForm(model, {usePlaceholder : true, onControlChanged: orderItemChanged});
  const render = (name) => searchGameYearForm.renderControl(name, null);

  const [games, setGames] = useState(playerData.Games);

  function orderItemChanged(e, control){
    if(control && control.name === 'years'){
      let _items = playerData.Games; 
      if(control.value && control.value !== ""){ 
        _items = JSON.parse(JSON.stringify(playerData.Games)).filter(item => {
          if(item.Year === parseInt(control.value)){
            return true;
          }
          return false
        });
      }
      setGames(_items);
    }
  }

  return(
    <Fragment>
      <div className="player__view">
        {/* <div css = {style.banner__image} className = "cms_item' 'single-banner' 'banner-item bannerSize--standard" 
          style={{ backgroundImage: utils.css.bgUrlStyle('http://nafc.com.au/-/nafc/lib/images/images_2560/pastplayers.jpg') }}>
        </div> */}
        <div className="container" css = {style.page__container}>
          {
            playerData ? 
            <Fragment>
              
              <h2 style={{marginBottom: '1rem'}}>
                { playerData.Surname + ((playerData.OtherNames && playerData.OtherNames !== "") ? ", " + playerData.OtherNames : "")}
              </h2>

              <div className="row">
                
                <div className="col-sm-8">
                  
                  <div css = {style.playerInfo1}>
                    <span className="player__info1__title"> Preferred Name: </span>
                    <span className="player__info1__value"> {playerData.CommonName} </span>
                  </div>

                  <div css = {style.playerInfo1}>
                    <span className="player__info1__title"> Born: </span>
                    <span className="player__info1__value"> { playerData.ConvertedDob } </span>
                  </div>

                  {
                    playerData.ConvertedDod &&
                    <div css = {style.playerInfo1}>
                      <span className="player__info1__title"> Deceased: </span>
                      <span className="player__info1__value"> { playerData.ConvertedDod } </span>
                    </div>
                  }

                  <table css={style.competition__info}>
              <thead>
                <tr>
                  <th> Competition </th>
                  <th> Total Games </th>
                  <th> Total Goals </th>
                </tr>
              </thead>
              <tbody>
                      <tr>
                        <td> League </td>
                        <td> { totalGamesGoals.totalLequgeGames } </td>
                        <td> { totalGamesGoals.totalLeagueGoals } </td>
                      </tr>
                      <tr>
                        <td> Other </td>
                        <td> { totalGamesGoals.totalOtherGames } </td>
                        <td> { totalGamesGoals.totalOtherGoals } </td>
                      </tr>
                </tbody>

                <tfoot>
                      <tr>
                        <td> Total </td>
                        <td> { totalGamesGoals.totalLequgeGames + totalGamesGoals.totalOtherGames } </td>
                        <td> { totalGamesGoals.totalLeagueGoals + totalGamesGoals.totalOtherGoals } </td>
                      </tr>
                    </tfoot>
              </table>

                  {
                    (playerData.Biography && playerData.Biography != null) && 
                    <div css = {style.playerInfo1}>
                      <span className="player__info1__title"> Biography: </span>
                      {/* <span className="player__info1__value">
                         { playerData.Biography }
                      </span> */}
                      <span className="player__info1__value" dangerouslySetInnerHTML={{ __html: playerData.Biography }}></span>
                    </div>
                  }

                </div>

                <div className="col-sm-4">
                <div css={style.thumbnails}>
                <div css={style.thumbnail} className="thumbnail">
                         
                         {
                           (playerData.PhotoUrl && playerData.PhotoUrl !== '') &&
                           <div>
                              <img src={ playerData.PhotoUrl } alt="photoimage"/>
                            </div>
                         } 
                </div>
                </div>
                </div>

              </div>
              
              <div css={style.gameInfo_games} >
                <div>
                  <span className="main_title"> Games </span>
                  <span> (Select a game to view) </span>  
                </div>
                {render('years')}
                {
                  games.map((item, index) =>
                    <div className="row item_container" key = {index}>
                      <div className="col-12">
                        {/* <SiteLink to = {`/history-game/${item.GameId}`} className="item__link">
                         { (index + 1) + ". " + utils.date.formatDDMMYYYY(item.GameDate)}
                        </SiteLink>  */}
                        <a href = {`/history/search?type=game&id=${item.GameId}`} className="item__link" onClick={() => {
                          utils.site.setupLsForSearchUrl(window.location.pathname  + "?type=player&id=" + playerId);
                        }}>
                         { (index + 1) + ". " + utils.date.formatDDMMYYYY(item.GameDate)}
                        </a> 
                      </div>
                      
                      <div className="col-12 col-sm-6">
                        <span className="game__item__title"> Competition : </span>
                        <span> {item.CompetitionName} </span>
                      </div>
                      
                      <div className="col-12 col-sm-6">
                        <span className="game__item__title"> Game Type : </span>
                        <span> {item.GameTypeName} </span>
                      </div>

                      <div className="col-6">
                        <span className="game__item__title"> Round : </span>
                        <span> {item.Round} </span>
                      </div>

                      <div className="col-12 col-sm-6">
                        <span className="game__item__title"> Opponent : </span>
                        <span> {item.OpponentTeam} </span>
                      </div>

                      <div className="col-6">
                        <span className="game__item__title"> Result : </span>
                        <span> {item.Result} </span>
                      </div>

                      <div className="col-6">
                        <span className="game__item__title"> Jumper : </span>
                        <span> {item.Guernsey} </span>
                      </div>

                      <div className="col-6">
                        <span className="game__item__title"> Goals : </span>
                        <span> {item.Goals} </span>
                      </div>

                      <div className="col-6">
                        <span className="game__item__title"> Points : </span>
                        <span> {item.Points} </span>
                      </div>

                    </div>
                  )
                }
              </div>
            </Fragment> : "No Player Page"
          }
        </div>
      </div>
      {/* <ViewPartial /> */}
    </Fragment>
  )
}