/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect,useState } from 'react';
import utils from '../../utils';
//import cmsUtils from '../../cms/utils/cmsUtils';
//import { usePost } from '../../components/';
import env from '../../env';
import style from './style';

export function OnlineScoreboard(props){
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const cssClass = utils.classNames('cms_item', 'online__scoreboard', item.cssClass || item.anchorName || '');

  //const post = usePost();
  const [scoreboardInfo, setScoreboardInfo] = useState({ scoreBoard : 0, timeStamp : 0});
  //const [scoreboardResult, setScoreboardResult] = useState({board : {}, comments : []});
  const [scoreboardResult, setScoreboardResult] = useState({ comments : []});
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    let timer = null;
    if (cmsOption.isCmsEdit){
      return;
    }
    const fetchData = async () => {

      const res = await fetch(env.apiBase + "/api/proxy/getscoreboard", {
        method : 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scoreboardInfo)
      });
      const data = await res.json();

      if(data.results){

        const _data = data.results.Result;
        //console.log("data", _data);

        if(_data.ScoreboardId === 0 || scoreboardInfo.scoreBoard !== _data.ScoreboardId){
          
        }
        
        if(_data.Updates.length > 1 && _data.ScoreboardId > 0){
          
          const _board = _data.Updates[0];
          //console.log('_board', _board);
          _data.Updates.splice(0, 1);
          
          _data.Updates.forEach(el => {
            const _comment = scoreboardResult.comments.find(sEl => sEl.id === el.id);

            if(_comment){
              if(el.active){
                _comment.comment = el.comment;
              }
              else{
                const _index = scoreboardResult.comments.findIndex(_comment);
                scoreboardResult.comments.splice(_index, 1);
              }
            }
            else{
              if(el.active){
                scoreboardResult.comments.unshift(el);
              }
            }

          });

          setScoreboardResult({board : _board, comments : scoreboardResult.comments });

        }

        setScoreboardInfo(
          { scoreBoard : parseInt(_data.ScoreboardId), timeStamp : parseInt(_data.Timestamp) }
        );

        timer = setTimeout(() => { 
          //console.log(data.results.Result.ScoreboardId, data.results.Result.Timestamp);
          console.log('runing timer');
          setTrigger(trigger + 1);
        }, 15000);
      }
    }
    
    fetchData();

    return () => {
      if(timer){
        clearTimeout(timer);
      }
    }

    // eslint-disable-next-line
  }, [trigger]);

  const getLogoImage = ((teamName) => {
    const _teamName = teamName.toLowerCase();
    let logoPath = "";
    if(_teamName.indexOf("nafc") >= 0 || _teamName.indexOf("north") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/roosters.png";
    }
    else if(_teamName.indexOf("central") >= 0 || _teamName.indexOf("district") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/bulldogs.png";
    }
    else if(_teamName.indexOf("sturt") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/sturt.png";
    }
    else if(_teamName.indexOf("wwtfc") >= 0 || _teamName.indexOf("eagles") >= 0 || _teamName.indexOf("woodville") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/eagles.png";
    }
    else if(_teamName.indexOf("wfc") >= 0 || _teamName.indexOf("west") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/west-adelaide.png";
    }
    else if(_teamName.indexOf("safc") >= 0 || _teamName.indexOf("south") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/panthers.png";
    }
    else if(_teamName.indexOf("pafc") >= 0 || _teamName.indexOf("port") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/magpies.png";
    }
    else if(_teamName.indexOf("redlegs") >= 0 || _teamName.indexOf("norwood") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/norwood.png";
    }
    else if(_teamName.indexOf("gfc") >= 0 || _teamName.indexOf("glenelg") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/tigers.png";
    }
    else if(_teamName.indexOf("afc") >= 0 || _teamName.indexOf("adelaide") >= 0){
      logoPath = logoPath + "/Common/imgs/logo/SANFL/adelaide.png";
    }
    return logoPath;
  });
  
  if (cmsOption.isCmsEdit){
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Online Scoreboard </b>
        </pre>
        </div>
    )
  }

  return(
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      
      {
        scoreboardResult.board &&
        <Fragment>
        <div className="row" css={style.scoreBoardInfo}>
          <div className="col-12 col-sm-8" style={{textAlign: 'center', fontWeight: '600'}}>{scoreboardResult.board.gameTitle + " | " + scoreboardResult.board.gameDate}</div>
          <div className="col-12 col-sm-4" style={{textAlign: 'center'}}>{ scoreboardResult.board.quaters && scoreboardResult.board.quaters.toUpperCase() }</div>
        </div>
        <div css={style.scoreboard__container}>
          <div css={style.scoreboard} className="row">
            <div className="col-5">
              <div className="logo__container">
                <img className="logo" src = {getLogoImage(scoreboardResult.board.hometeam)} alt = "logo1" />
              </div>
              <div className="team__name">
                { scoreboardResult.board.hometeam }
              </div>
              <div className="score">
                <span>{ scoreboardResult.board.homegoals }</span>
                <span>{ scoreboardResult.board.homebehinds }</span>
                <span className="points">{ scoreboardResult.board.homegoals * 6 + scoreboardResult.board.homebehinds * 1}</span>
              </div>
            </div>
            <div className="col-2 vs"> VS </div>
            <div className="col-5">
              <div className="logo__container">
                <img className="logo" src = {getLogoImage(scoreboardResult.board.awayteam)} alt = "logo2" />
              </div>
              <div className="team__name">
                { scoreboardResult.board.awayteam }
              </div>
              <div className="score">
                <span>{ scoreboardResult.board.awaygoals }</span>
                <span>{ scoreboardResult.board.awaybehinds }</span>
                <span className="points">{ scoreboardResult.board.awaygoals * 6 + scoreboardResult.board.awaybehinds * 1}</span>
              </div>
            </div>
          </div>
        </div>
        <div style = {{backgroundColor: "#f3f0f0"}}>
            <div css={style.comment__list}>
            <div className="title__text">Running Commentary</div>
            {
              scoreboardResult.comments.map((item, index) => <p id = {'comment_' + item.id} key={index} dangerouslySetInnerHTML={{ __html: item.comment }}></p>)
            }
            </div>
          </div>
        </Fragment>
      }
    </div>
  )

}